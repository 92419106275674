.footer {
  padding:2em 0 1em;
  background-color: $footer-bg;
  @media (max-width:767px) {
    font-size: 0.875em;
  }
  .footer-info,
  .footer-links {
    // margin-bottom: 2.5em;
    margin-bottom: 0;
    @media (max-width:767px)
    {  margin-bottom: 0;
      // margin-bottom: 3em;
    }
  }
  .footer-info {
    img.footer-logo {
      width: auto;
      max-width: inherit;
      max-height: 2.5em;
    }
  }
  h6 {
    line-height: 1;
    margin-bottom: 1.25em;
    font-size: 1.231em;
    color: $white-color;
  }

  .bottom-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    font-size: 14px;
    @media (max-width:767px)
    {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .footer-copyright {
      p {
        margin-bottom: 0px;
        color: $grey-color;
        color: $white-color;
        
      }
    }
    .bottom-footer-socialstext-end
    {
      @media (max-width:767px)
        {
          margin-top: 1.3em;
        }
      list-style: none;
      padding-left: 0;
      display: flex;
      li{
        a{
          margin-right: 1.9em;
          color: $white-color;
       
          svg{
            transition: 0.25s ease-in;
          }
          &:hover{
           svg{
            transform: scale(1.2);
           }
          }
        }
        &:last-child{
          a{
            margin-right: 0;
          } 
        }
      }
    }
  }

  hr {
    border-top: 0.8px $grey-color solid;
  }

  ul
  {
    list-style:disc;
    color: $white-color;
    padding-left: 15px;
  }

  .foo-links
  {
    li{
      p{
        a{
          color: $white-color;
          font-size: 14px;
        }
      }
    }
  }

  .foo-address
  {
    list-style: none;
    padding-left: 0;
    li{
      display: flex;
      align-items: flex-start;
      margin-bottom: 1.1em;
      font-size: 14px;
      svg{
        margin-right: 0.6em;
        position: relative;
        top: 3px;
        min-width: 19px;
      }
      a{
        color: $white-color;
        
      }
    }
  }
}

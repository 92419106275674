
.stepClass
{
    @media (max-width:767px) {
       font-size: 0.8em;
    }
    padding: 0;
    ._2kL0S
    {
        &._2kL0S
        {
            color: transparent;
        }
        height: 14px;
        width: 14px;
      
    }
    ._2JvrO
    {
       
        svg{
            display: none;
        }
        height: 14px;
        width: 14px;
    }
    ._2Jtxm ._2kL0S, 
    ._2Jtxm ._2JvrO
    {
        background-color: #D9D9D9;
    }
    ._2Jtxm._35Ago ._2kL0S
    {
        background: $btn-color !important;
    }
    ._2Jtxm._35Ago 
    {
        &::before{            
            background: $btn-color !important;
        }
        ._1hzhf 
        {
            color: $secondary-color;
            font-weight: 600;
           width: 100%;
        }
    }
    ._2Jtxm 
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        ._1hzhf 
        {
            top: -28px;
            color: $grey-color;
            font-size: 0.9em;
            @media (max-width:767px) {
                font-size: 0.6em;
                display: none;
            }
        }
        &._2ZUAI 
        {
            &::before{
                background: $btn-color;
            }
        }


     
        &._2ZUAI 
        {
            ._2JvrO
            {
                background-color: $btn-color;
            }
        }

       
    }
    ._2_g61
    {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
      
    }
    ._1Lo2h
    {
        background-color: $white-color;
        border-radius: 10px;
        padding: 2.5em 0 1.5em;
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        @media (max-width:767px) {
            padding: 1.5em 0 1.5em;
        }
    }
    ._3uApM
    {
        
        background-color: $white-color;
        padding: 2em 0.8em;
        justify-content: center;
        ._2pGos
        {
            font-size: 1em;
            border-radius: 6px;
            padding: 0.7em 1.3em;
            width: auto;
            font-weight: 500;
            background-color: $btn-color;
            border-color: $btn-color;
            color: $white-color;
            transition: 0.25s ease-in;
            text-align: center;
            &:hover {
                background-color: $white-color !important;
                border-color: $btn-color !important;
                color: $secondary-color !important;
            }
            &:focus {
                background-color: $btn-color;
                border-color: $btn-color;
                color: $secondary-color;
            }
            &._3CDiP
            {
                background-color: $white-color;
                border-color: $btn-color;
                color: $secondary-color;
                transition: 0.25s ease-in;
                margin-right: 1em;
                &:hover {
                    background-color: $btn-color !important;
                    border-color: $btn-color !important;
                    color: $white-color !important;
                  }
                  &:focus {
                    background-color: $btn-color;
                    border-color: $btn-color;
                    color: $secondary-color;
                  }
            }
           
        }
    }
}

.stepMain
{
    margin-bottom: 10em; 
    @media (max-width:767px) {
        margin-bottom: 2em;
    }  
    padding-top: 1.2em;
}

._53Ji7
{
    padding: 0;
}
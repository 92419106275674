@media only screen and (min-width: 1700px) {}

@media only screen and (min-width: 1400px) {}

@media only screen and (max-width: 1280px) {
  .navbar-toggler {
    border-color: $primary-color;

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27><path stroke=%27rgba%280, 0, 0, 1%29%27 strokeLinecap=%27round%27 strokeMiterlimit=%2710%27 strokeWidth=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/></svg>");
    }

    &:focus {
      box-shadow: none;
    }
  }


  .navbar-expand-lg {

    .navbar-nav {
      &.newNav {
        display        : flex;
        flex-direction : row;
        justify-content: flex-end;
      }

      .nav-link {

        padding  : 1em 0.6em;
        font-size: 1em;

        &.active {
          color: $primary-color;
        }

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    .navbar-nav {
      align-items: center;
    }
  }
}

@media only screen and (max-width: 991px) {
  .navbar {
    .navbar-collapse {
        position  : absolute;
        top       : 100%;
        background: $white-color;
        width     : calc(100% - 20px);
        padding   : 20px;
        box-shadow: 0px 10px 10px rgba($black-color, 0.07);
        left      : 10px;
        right     : 0px;
        a{
          &.nav-link
          { border-radius: 5px;font-size: 14px;
            &.active , &:hover {
              background-color:$btn-color;
              color: $white-color !important;
              svg{
                path {
                  color: $white-color;
              }
              }
            }
            svg {
              margin-right: 0.5em;
              width: 20px;
              height: 20px;
              min-width: 20px;
              path {
                color: rgba(35, 31, 47, 0.2);
            }
          }
          }
        }
      }
  }

  .btnBox {
    @media (max-width:767px) {
      position: relative;
      top     : 0;
      right   : -15px;

      .btn {
        font-size: 0.8em !important;
        padding  : 0.8em 0.8em !important;
        width    : auto;

      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .btnBox {
    margin-left: 0;
   a{
    &.btn{
      line-height:40px!important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
   }
  }

  .navbar-expand-lg {

    .navbar-brand {
      img {
        width: 6em;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .navbar-expand-lg {
    .navbar-brand {
      img {
        width: 4em;
      }
    }
  }
}

@media only screen and (min-width:1200px) and (max-width:1399.98px) {
  .container {
    max-width: 1170px;
  }
}
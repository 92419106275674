@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap");
@import "./component/sass/Variables.scss";
@import "./component/sass/CustomButtons.scss";
@import "./component/sass/_LandingPages";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  margin: 0 !important;
}

body {
  overflow-x: hidden;
}

.minwidth200 {
  min-width: 200px;
  max-width: 200px !important;
}

.bg-primary {
  background-color: $white-color !important;
}

.relative {
  position: relative;

  .absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% -50%);
  }
}

.replyComment {
  position: relative;

  .replyCommentName {
    position: absolute;
    color: blue;
    font-weight: bold;
    z-index: 0;
    top: 30%;
    left: 5px;
    width: 3rem;
  }
}

.bold {
  font-weight: bold;
}

// .infinite-scroll-component {
//   overflow-x: none !important;
// }
.navbar-expand-lg {
  padding-top: 1.1em;
  padding-bottom: 1.1em;

  .navbar-collapse {
    flex-direction: column;
  }

  .navbar-brand {
    img {
      width: 100px;
      max-height: 49px;
    }
  }

  .navbar-nav {
    &.newNav {
      .nav-link {
        padding: 0.6em 1.2em;
      }
    }

    .nav-link {
      color: $black-color;
      padding: 1em 1.2em;
      font-size: 1em;

      &.active {
        color: $primary-color1;
      }

      &:hover {
        color: $primary-color1;
      }
    }
  }

  .rightSide {
    display: flex;
    align-items: center;
    margin-left: auto;
    position: absolute;
    right: 0;

    a {
      width: 38px;
      height: 38px;
      display: inline;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      font-size: 1em;
      border-radius: 6px;
      padding: 0.7em 1.3em;
      font-weight: 500;

      &:focus {
        color: $white-color;
      }

      &.noti {
        background-color: $primary-color;
      }

      &.chat {
        background-color: $primary-color1;
        margin: 0.7em;
      }

      img {
        width: 38px;
        height: 38px;
        border-radius: 100px;
      }
    }

    .btnBox {
      .btn {
        width: 115px;
        height: 40px;
        display: inline-block;
        padding: 0;
        line-height: 38px;
        @media only screen and (max-width: 767px) {
         width: auto;
      }
      }
    }

    @media (max-width: 991px) {
      right: 93px;
      // top: 0;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
  transition: all ease 0.3s;

  &:hover {
    color: $btn-color;
  }
}

.form-check {
  label {
    position: relative;
    top: 3px;
    left: 5px;
  }
}

.btnBox {
  margin-left: 1.2em;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: $grey-color;
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;

  &:hover {
    color: #353f4f !important;
    text-decoration: none;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.setpcommonpad {
  padding: 5em 0 0;

  @media (max-width: 767px) {
    padding: 2em 0 0;
  }
}

.bgColor {
  background-color: $light-color;
}

/* Study Section */

h1.heading {
  font-size: 1.8em;
}

p.para {
  font-size: 0.875em;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 2em;
  color: $grey-color;
}

.setpBox {
  @media (max-width: 767px) {
    margin: 0 15px;
  }

  text-align: center;

  label {
    margin-bottom: 0.6em;

    .card-input-element {
      display: none;
    }

    .card-input {
      padding: 0.6em 1.6em;
      border-radius: 100px;
      background-color: $white-color;
      font-weight: 600;
      margin-right: 0.6em;
      border: 1.5px solid $black-color;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: 767px) {
        padding: 0.6em 1.1em;
        margin-right: 0.3em;
      }

      svg {
        margin-right: 0.2em;
        position: relative;
        top: -2px;
        animation: mymove 1.5s linear;
        width: 17px;
        height: 17px;

        @media (max-width: 565px) {
          width: 14px;
          height: 14px;
        }
      }

      @media (max-width: 565px) {
        font-size: 0.8em;
      }

      @keyframes mymove {
        0% {
          transform: rotate(0deg);
        }

        25% {
          transform: rotate(0deg);
          animation-delay: 3s;
        }

        50% {
          animation-delay: 5s;
          transform: rotate(-180deg);
        }

        75% {
          transform: rotate(-180deg);
          animation-delay: 5s;
        }

        100% {
          transform: rotate(0deg);
        }
      }
    }

    .card-input-element:checked + .card-input {
      background-color: $btn-color;
      color: $white-color;

      svg {
        path {
          fill: $white-color;
        }
      }
    }
  }
}

.campus_box {
  .col-lg-6,
  .col-lg-4 {
    margin-bottom: 1.5em;
  }

  .campus_body {
    background-color: $white-color;
    padding: 1.2em 1.2em 1.4em;
    border-radius: 20px;
    text-align: left;
    height: 100%;

    h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.4em;
      font-weight: 600;
    }

    p {
      color: $grey-color;
      font-size: 0.875em;
      line-height: 1.7em;
      margin: 0;
    }

    &.campus_body1 {
      text-align: center;

      img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        margin-bottom: 1em;
      }

      h2 {
        font-size: 1.1em;
        font-weight: 600;
        justify-content: center;
        margin-bottom: 0.2em;
        min-height: 43px;
      }

      p {
        margin-bottom: 1em;
      }
    }

    &.campus_body2 {
      position: relative;

      .para {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          display: flex;
          align-items: center;
          justify-items: center;
          margin-bottom: 0;
        }
      }

      h5 {
        font-size: 0.875em;

        span {
          text-decoration: line-through;
        }
      }

      h6 {
        font-size: 1.5em;
        color: $btn-color;

        &.totalLable {
          font-size: 1.25em;
          font-weight: 700;
          color: $black-color;
        }
      }

      .avgDetails {
        margin-top: 1.3em;
        margin-bottom: 1.2em;
        display: flex;
        justify-content: space-around;

        h5 {
          font-size: 0.7em;
          color: $grey-color;
          margin-bottom: 0.2em;
        }

        h4 {
          font-size: 1.25em;
          font-weight: 700;
        }
      }

      .proBox {
        margin-bottom: 1em;

        .proDat {
          margin-bottom: 0.2em;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-size: 0.875em;
            color: $black-color;
            font-weight: 500;
          }
        }
      }

      .menuDots1 {
        position: absolute;
        right: 0;
        top: 9px;
      }
      .dropdown {
        .menuDots.dropdown-toggle {
          background: transparent;
          padding: 0px;
          border: 0px;
          outline: 0px;
          box-shadow: none;
          &:after {
            display: none;
          }
        }
      }
    }

    .meetsBadge {
      background-color: rgba(58, 56, 115, 0.2);
      display: inline-block;
      padding: 0.4em 1.6em;
      font-size: 0.875em;
      border-radius: 100px;
      color: $secondary-color;
    }

    .badgeFlex {
      .badge {
        background-color: rgba(96, 125, 219, 0.2);
        display: inline-block;
        padding: 0.6em 1.2em;
        font-size: 0.875em;
        border-radius: 100px;
        font-weight: 400;
        color: #607ddb;
        margin-right: 0.4em;

        @media (min-width: 992px) and (max-width: 1199px) {
          margin-bottom: 0.6em;
        }
      }
    }

    .followWidth {
      display: flex;

      .btn {
        flex-grow: 1;

        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 0.8em;
        }
      }
    }
  }
}

.followBtn {
  padding: 0.7em 1.2em;
  width: inherit;
  font-size: 0.875em;
  color: $btn-color;
  border-radius: 5px;

  svg {
    margin-right: 0.4em;
    width: 17px;
    height: 17px;
    position: relative;
    top: -1px;
  }

  &.activefollow {
    background-color: $btn-color !important;
    color: $white-color;

    svg {
      path {
        fill: $white-color;
      }
    }
  }

  &.activeBtn {
    background-color: $white-color !important;
    border-color: $primary-color !important;
    color: $primary-color;

    svg {
      path {
        fill: currentColor;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $primary-color !important;
    }
  }

  &.activeAddlist {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: $white-color;

    svg {
      path {
        fill: currentColor;
      }
    }
  }

  &:hover,
  &:focus {
    color: $white-color;

    svg {
      path {
        fill: $white-color;
      }
    }
  }
}

.commonpad {
  padding: 1.25em 0 0.5em;
  // padding-top:  1.25em;
}

.hidehead {
  opacity: 0;
  margin-top: -80px;
  transition: all 0.25s ease-in-out;
  z-index: -1;
  position: relative;
}

.scroll-to-top-button {
  width: 35px;
  height: 35px;
  background: $btn-color;
  border: none;
  color: #fff;
  border-radius: 6px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 9;
}

.showhead {
  transition: all 0.25s ease-in-out;
}

.mb15 {
  margin-bottom: 1.5em;
}

.cardBox {
  background-color: $white-color;
  border-radius: 20px;
  padding: 2.1em 1.2em;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 1.5em;
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  h2 {
    font-size: 1.375em;
  }

  p {
    font-size: 0.875em;
    color: $grey-color;
    width: 100%;
    max-width: 400px;
    margin: 0 auto 2em;
  }

  .btn {
    width: 100%;
    max-width: 200px;
    margin-top: auto;
  }

  &.cardBox1 {
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }

    .btn {
      max-width: 100%;
    }
  }
}

.headingh2 {
  font-size: 1.375em;
  font-weight: 600;
  margin-bottom: 0;

  @media (max-width: 767px) {
    font-size: 1em;
  }
}

// .headingh4 {
//   font-size    : 1.125em;
//   font-weight  : 600;
//   margin-bottom: 0;

//   @media (max-width: 767px) {
//     font-size: 0.975em;
//   }
// }
.minheight38 {
  min-height: 38px;
}
.linkseeall {
  color: $btn-color !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
}

.discoverBox {
  background-color: $white-color;
  border-radius: 15px;
  padding: 0.8em 1em;
  text-align: center;
  margin-bottom: 1.5em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);

  a {
    padding: 0.8em 1.5em;
    display: inline-flex;
    align-items: center;
    font-size: 0.875em;
    margin-bottom: 10px;
    color: $secondary-color;
    font-weight: 500;
    border-radius: 5px;

    @media only screen and (max-width: 991.98px) {
      margin-bottom: 0px;
    }

    svg {
      margin-right: 0.5em;
      width: 17px;
      height: 17px;

      path {
        color: rgba($secondary-color, 0.2);
      }
    }

    &.active,
    &:hover {
      background-color: $btn-color;
      border-radius: 5px;
      color: $white-color !important;

      svg {
        path {
          color: $white-color !important;
        }
      }

      // svg{
      //   path{
      //     fill: $white-color
      //   }
      // }
    }
  }

  &.discoverBox1 {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 1.75em;

    @media only screen and (max-width: 1199.98px) {
      padding: 0.8em 0em;
    }

    @media only screen and (max-width: 991.98px) {
      padding: 0.8em 1em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $btn-color;
    }

    a {
      white-space: nowrap;
      flex-grow: 1;

      svg {
        margin-right: 0.5em;
        width: 20px;
        height: 20px;
        min-width: 20px;
      }
    }
  }

  &.discoverBox2 {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 99;

    a {
      margin-bottom: 0px;
    }

    @media (max-width: 767px) {
      justify-content: inherit;

      a {
        white-space: nowrap;
        flex-grow: 1;

        svg {
          margin-right: 0.5em;
          width: 20px;
          height: 20px;
        }
      }
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $btn-color;
    }
  }
}

.cmb15 {
  margin-bottom: 1.5em;
}

.modal_css {
  &.modal_cssWidth {
    .modal-dialog {
      max-width: 600px;
    }
  }

  .modal-body {
    padding: 1.2em 1.8em;

    .modalheading_box {
      border-bottom: 1px solid #d8d6de;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.1em;
      padding-bottom: 1.1em;

      h1 {
        font-size: 1.5em;
        padding-bottom: 0.8em;
        margin-bottom: 0.8em;
        font-weight: 600;
      }

      h2 {
        margin: 0;
        font-size: 1.5em;
      }

      a {
        width: 25px;
        height: 25px;
        background-color: $btn-color;
        display: flex;
        border-radius: 100px;
        color: #fff;
        justify-content: center;
        align-items: center;

        &:hover {
          opacity: 0.8;
        }
      }
    }
    .modal_footer_margin {
      margin-top: 4rem !important;
    }
    .modal_footer {
      text-align: center;
      margin-top: 1.9em;

      .btn {
        width: 100%;
        max-width: 160px;
        font-size: 0.875em;

        @media (max-width: 767px) {
          max-width: 130px;
          font-size: 0.8em;
        }
      }
    }

    .edit_listpref {
      margin-bottom: 1.6em;

      li {
        font-size: 0.875em;
        font-weight: 500;
        display: flex;
        align-items: center;

        span {
          width: 17px;
          display: inline-block;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 5px;
          margin-right: 0.6em;
        }

        margin-bottom: 1em;

        a {
          background-color: $primary-color;
          font-size: 0.875em;
          margin-left: auto;
          padding: 0.5em 0.8em;
          border-radius: 5px;
          color: $white-color;

          &:hover {
            opacity: 0.8;
            color: $white-color !important;
          }
        }
      }
    }

    .coll_edit {
      text-align: center;
      font-weight: 300;
      font-size: 0.875em;
      margin: 0;
    }

    .modal_Inner {
      text-align: center;

      img {
        width: 250px;
      }

      h1 {
        font-size: 1.7em;
        margin: 1em 0 0.5em;
      }

      p {
        font-size: 0.9em;
        color: #6b6e6f;
      }
    }
  }

  .mHeader {
    h1 {
      font-size: 1.5em;
      border-bottom: 1px solid #d8d6de;
      padding-bottom: 0.8em;
      margin-bottom: 0.8em;
      font-weight: 600;
    }
  }

  .form-label {
    font-size: 0.875em;
    font-weight: 600;
  }

  .fControl {
    height: 48px;
    border-radius: 12px;
    padding: 0.2em 0.8em;
    font-size: 0.875em;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;

    .uFlex {
      border-radius: 6px;
      flex-wrap: wrap;

      // margin-right: 0.5em;
      span {
        background-color: rgba(99, 100, 255, 0.11);
        border-radius: 6px;
        margin-top: 0.15em;
        margin-bottom: 0.15em;

        svg {
          color: #6364ff;
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
      }

      img {
        width: 30px;
        height: 30px;
        border-radius: 6px;
        background: rgb(99 100 255 / 32%);
      }

      h5 {
        padding-right: 0.7em;
        color: #6364ff;
        font-size: 12px;
        line-height: 12px;
      }
    }

    span {
      font-size: 0.875em;
      cursor: pointer;
    }
  }
}

.accCollapse {
  .col-lg-6 {
    margin-bottom: 1em;
  }

  label {
    font-size: 0.875em;
    margin-bottom: 0.2em;
    font-weight: 600;
    display: block;
  }

  .accBtn {
    width: 100%;
    font-size: 0.875em;
    text-align: left;
    padding: 0;
    font-weight: 400;
    align-items: center;
    border-radius: 12px;
    background-color: transparent;
    border-color: rgba(216, 214, 222, 1);
    color: $black-color;
    position: relative;

    .contFlex {
      padding: 0.9em 1.2em;
    }

    .contFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:hover {
      border-color: #d8d6de !important;
    }
  }

  .fomGroup {
    position: relative;
  }

  .collBody {
    padding: 0.7em 1.2em 1em;
    position: absolute;
    background-color: #fff;
    z-index: 2;
    transition: 0.25s ease-in;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-top: -10px;
    border-left: 1px solid #d8d6de;
    border-right: 1px solid #d8d6de;
    border-bottom: 1px solid #d8d6de;
    width: 100%;

    label {
      font-size: 0.875em;
      font-weight: 600;
    }

    .form-select {
      font-size: 0.875em;
    }

    .tabsBox {
      font-size: 0.875em;
      margin-top: 0.8em;

      .badge {
        background-color: rgba(96, 125, 219, 0.2);
        display: inline-block;
        padding: 0.6em 1.2em;
        font-size: 0.875em;
        border-radius: 100px;
        font-weight: 400;
        color: #607ddb;
        margin-right: 0.5em;
        cursor: pointer;
        margin-bottom: 0.4em;

        svg {
          color: #000;
          position: relative;

          &:hover {
            color: $red-color;
          }
        }
      }
    }

    .cityLabel {
      font-size: 0.8em;
      color: $grey-color;
      margin: 0.8em 0;
    }

    .form-check {
      .form-check-label {
        font-weight: 400;
        margin-bottom: 0;
        position: relative;
        top: 2px;
      }
    }

    .btn {
      font-size: 0.875em;
      padding: 0.7em 3.5em;
    }
  }
}

.form-select {
  height: 48px;
  border-radius: 12px;
  padding-left: 1em;
  font-size: 0.875em;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
  }
}

.form-control {
  height: 48px;
  border-radius: 12px;
  padding-left: 1em;
  font-size: 0.875em;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
  }
}

.form-check-input {
  &:checked {
    border-radius: 2px;
    background-color: #6750a4;
    border-color: #6750a4;
  }

  &:focus,
  &:active,
  &:hover {
    box-shadow: none !important;
  }
}

.filterBox {
  margin-bottom: 1.5em;

  .filterBox1 {
    padding: 15px;
    background-color: $white-color;
    border-radius: 10px;
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .dropdown-toggle {
      border: 1px solid $btn-color;
      border-radius: 5px;
      padding: 0.6em 1em;
      background-color: $white-color;
      display: flex;
      align-items: center;
      font-size: 0.8em;
      color: $btn-color;
      font-weight: 500;
      margin: 0 0.4em;

      @media (max-width: 767px) {
        justify-content: space-between;
        width: 100%;
      }

      &::after {
        font-size: 1.5em;
      }

      &.filterBtn {
        margin-left: 0em;
        border-color: transparent;

        svg {
          transition: all 0.4s ease-in;
        }

        &:hover {
          svg {
            opacity: 0.5;
          }
        }
      }
    }

    .dropdown-menu {
      border: none;
      box-shadow: none;
      background-color: transparent;
    }

    .posRelative {
      position: relative;

      .dropdownBox {
        z-index: 2;
        position: absolute;
        background-color: $white-color;
        width: 500px;
        margin-top: 1em;
        left: 0;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.07));
        padding: 15px;
        border-radius: 20px;

        @media (max-width: 1024px) {
          right: 0;
          left: initial;

          &.dropdownBoxLeft {
            right: inherit;
          }

          &.dropdownBoxLeftWidth {
            width: 400px;
          }

          &.dropdownBoxLeftWidth {
            width: 400px;

            .col-md-6 {
              margin-bottom: 1em;
            }
          }
        }

        @media (max-width: 991px) {
          &.dropdownBoxLeftWidth {
            width: 400px;

            .col-sm-6 {
              margin-bottom: 1em;
            }
          }
        }

        @media (max-width: 767px) {
          width: 300px !important;

          &.dropdownBoxLeftWidth {
            width: 300px;
          }

          right: initial;
          left: 0;
        }

        @media (max-width: 320px) {
          width: 270px;
        }

        .dropdownBox_heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #d8d6de;
          padding-bottom: 1em;
          margin-bottom: 1em;

          h2 {
            margin: 0;
            font-size: 1.5em;
            font-weight: 600;
          }

          .btn {
            background-color: transparent;
            padding: 0;
            border-color: transparent;
            margin-right: 0.1em;

            &:hover {
              border-color: transparent !important;

              svg {
                path {
                  fill: red;
                }
              }
            }
          }
        }

        .arrowAbs {
          position: absolute;
          top: -16px;
          width: 23px;
        }

        .clearAll {
          font-size: 0.875em;
          border-color: $btn-color;
          color: $btn-color;
          margin-top: 1.6em;
          width: 100%;
          max-width: 150px;
        }

        .setpBox {
          label {
            .card-input {
              padding: 0.6em 1.1em;
            }
          }
        }

        .degFlex {
          display: flex;

          svg {
            margin-bottom: 1.5em;
          }

          @media (max-width: 767px) {
            svg {
              width: 50px;
              margin-bottom: 0.2em;
            }
          }

          .deg_item {
            cursor: pointer;
            border-radius: 25px;
            padding: 30px 10px;
            flex-grow: 1;
            text-align: center;

            h3 {
              color: $black-color;
              font-weight: 700;
              font-size: 2.159em;
            }

            h4 {
              color: $black-color;
              margin-bottom: 0;
              font-size: 1.329em;
            }

            @media (max-width: 767px) {
              h3 {
                font-size: 1.5em;
              }

              h4 {
                font-size: 1em;
              }
            }

            &.active {
              background-color: $btn-color;

              h3 {
                color: $white-color;
              }

              h4 {
                color: $white-color;
              }
            }
          }
        }

        .sizeBox {
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
          background-color: $white-color;
          border-radius: 10px;
          padding: 15px;
          height: 100%;

          h3 {
            font-size: 1em;
          }

          svg {
            width: 20px;
          }

          p {
            font-size: 0.8em;
            margin: 0.6em 0 0;
            text-align: center;
            color: $grey-color;
            font-weight: 500sizeBox;
          }
        }
      }

      &.locationBox {
        .dropdownBox {
          width: 400px;
        }
      }

      &.settingBox_main {
        margin-right: 0.3em;

        .dropdownBox {
          width: 350px;
        }

        .settingBox {
          padding: 0 1.5em;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          @media (max-width: 767px) {
            padding: 0 0.6em;
          }

          .setItem {
            margin-bottom: 1em;
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: $white-color;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
            border-radius: 20px;

            h5 {
              font-size: 0.9em;
              margin-bottom: 0;
              margin-top: 0.8em;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .posRelative {
        margin-bottom: 0.6em;
        width: 100%;

        a {
          justify-content: space-between;
        }
      }

      .filterBtn {
        margin-left: 0 !important;

        @media (max-width: 767px) {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.cityLabel1 {
  font-size: 0.8em;
  color: $black-color;
  margin: 0.8em 0;
}

.check_bbx {
  label {
    h3 {
      font-size: 1em;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
      font-size: 0.8em;
      font-size: 300;
    }
  }

  .form-check {
    margin-bottom: 0.6em;
  }

  .form-check-input[type="checkbox"] {
    position: relative;
    top: 6px;
  }
}

.countyList {
  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 37%;
      margin-bottom: 0.6em;
      margin-right: 0.6em;

      &.active {
        background-color: $primary-color;
      }

      a {
        text-align: center;
        justify-content: center;
        height: 45px;
        color: $black-color !important;
        border-radius: 100px !important;
        border: 1px solid $black-color !important;
        padding: 0 !important;
        font-weight: 600 !important;
        display: flex;
        align-items: center;
        font-size: 0.8em;
        text-align: center;

        &:hover {
          background-color: $primary-color;
          border-color: $primary-color !important;
          color: $white-color !important;
        }

        @media (max-width: 767px) {
          justify-content: center !important;
        }
      }
    }
  }
}

.signinSection {
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  .cleftside {
    background-color: $btn-color;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 4em 15px 1.5em;
    @media (max-width: 767px) {
      display: none;
    }

    img {
      margin-bottom: 2.5em;
    }

    h2 {
      color: $white-color;
      margin-bottom: 0.4em;
    }

    p {
      color: $white-color;
      font-weight: 300;
    }
  }

  .w49 {
    width: 49%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .crightside {
    background-color: $light-color;
    padding: 3em 6em 3em;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1191px) {
      padding: 1.5em 2em 1.5em;
    }

    .loginHeading {
      text-align: center;
      margin-bottom: 2.5em;

      h1 {
        font-size: 1.875em;
      }

      p {
        font-size: 1em;
        color: $grey-color;
      }
    }

    .form-label {
      font-weight: 600;
      font-size: 0.875em;
      margin-bottom: 0.3em;
    }

    .form-control:focus,
    .form-control:active {
      box-shadow: none;
      border-color: $primary-color;
    }

    input.form-control {
      height: 50px;
      border-radius: 12px;
      font-size: 0.9em;
      padding-left: 1.5em;
      padding-right: 1.5em;
      border-color: #d8d6de;

      @media (max-width: 767px) {
        height: 50px;
        font-size: 0.8em;
      }
    }

    .passwordPos {
      position: relative !important;

      .form-control {
        padding-right: 3.4em;
      }

      svg {
        position: absolute;
        top: 16px;
        right: 19px;
        font-size: 1.2em;
        cursor: pointer;
      }
    }

    .forFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.3em;
      font-size: 14px;

      a {
        font-size: 14px;
        font-weight: 700;
        color: $black-color;
        text-decoration: underline;
      }
    }

    .btn {
      width: 100%;
      height: 55px;
      border-radius: 12px;
    }

    a {
      &.btn {
        line-height: 30px;
      }
    }

    .footBox {
      margin-top: 1.5em;
      font-weight: 500;
      text-align: center;

      p {
        font-size: 0.9em;
        color: #292929;
        margin-bottom: 0;

        a {
          font-weight: 600;
          color: $btn-color;
          text-decoration: underline;
        }
      }
    }
  }
}

.homePad {
  padding: 1.25em 0 0.5em;

  &.cardHome {
    padding-top: 1.25em;
  }
}

.genre-post {
  span {
    border-radius: 6px;
    background-color: rgba(99, 100, 255, 0.11);
    font-size: 0.8em;
    margin-right: 0.4em;
    margin-bottom: 0.4em;
    margin-right: 0.4em;
    color: $btn-color;
    padding: 3px 8px;
  }
}

.cardHome {
  padding-top: 5px;

  .card {
    padding: 15px !important;
    border-color: $white-color;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    border-radius: 12px;

    .collargeImage {
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 1em;
      position: relative;

      .one-media {
        width: 100%;
        height: auto;
        object-fit: contain;
        max-height: 550px;
        // background-color: #000;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        // align-items: center;
        gap: 0.5em;

        .gallery-picture {
          display: block;
          position: relative;
          flex: 1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // max-height: 550px;
            max-height: 470px;
            background-color: #f1f1f1;
          }

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 550px;
            background-color: #f1f1f1;
          }
        }
      }

      .more-media {
        display: flex;
        gap: 0.25em;
        flex-direction: row;

        .more-media_box {
          flex-grow: 1;
          flex-wrap: wrap;
          display: flex;
          gap: 0.25em;

          .gallery-picture {
            height: 157px;
            flex-basis: calc(49% - 0px);
            position: relative;

            a {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              color: $white-color;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &.mediaTwo {
            .gallery-picture {
              &:nth-child(1) {
                flex-basis: 100%;
              }

              &:nth-child(2) {
                flex-basis: 100%;
              }
            }
          }

          &.mediaThree {
            .gallery-picture {
              &:nth-child(3) {
                flex-basis: 100%;
              }
            }
          }
        }

        .gallery-picture {
          flex-basis: 55%;
          display: block;
          max-height: 323px;
          background: #f1f1f1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        @media (max-width: 1199px) {
          .gallery-picture {
            flex-basis: calc(48% - 0px);
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;

          .gallery-picture {
            flex-basis: calc(100% - 0px);
            max-height: initial;
          }

          .more-media_box {
            .gallery-picture {
              flex-basis: calc(48% - 0px);
            }
          }
        }
      }

      ._30i9K {
        display: flex;
        gap: 1em;
        flex-wrap: wrap;

        img {
          width: 144px;
          height: 149px;
          object-fit: cover;
          border-radius: 20px;
        }

        video {
          width: 144px;
          height: 149px;
          object-fit: cover;
          border-radius: 20px;
        }
      }

      // .simg {
      //   height: 157px;
      //   width: 100%;
      //   object-fit: cover;
      //   border: 2px solid $white-color;
      // }
    }

    p {
      font-size: 0.775em;
      color: #585662;

      strong {
        color: $black-color;
      }
    }

    .passion-heading {
      font-size: 20px;
      font-weight: 700;
    }

    .linkUrlpassion {
      color: $btn-color !important;
      font-size: 14px;

      svg {
        font-size: 18px;
      }
    }

    .post-description {
      color: #39373f;
      line-height: 1.5;
      word-spacing: 0.1rem;
      font-size: 1em;
      margin-bottom: 0.5em;
      // letter-spacing: 1px;
    }

    .cardCount {
      display: flex;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        margin-right: 1.5em;

        svg {
          margin-right: 0.2em;
          color: $black-color;
          transition: 0.35s ease-in;
        }

        &:hover {
          svg {
            color: $btn-color;
          }
        }
      }
    }

    &.carTop {
      margin-top: 1em;
    }
  }

  .createPostContent {
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 280px;
    }

    p {
      font-size: 0.9em;
      color: $btn-color;
      width: 100%;
      max-width: 450px;
      margin: 0 auto 2em;
    }
  }
}

.menuDots {
  background-color: transparent;
  color: $black-color;
  border-color: transparent;

  svg {
    transition: all ease 0.3s;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border-color: transparent !important;

    svg {
      color: $btn-color;
    }
  }
}

.mediaUser {
  display: flex;
  align-items: center;

  img {
    width: 55px;
    height: 55px;
    border-radius: 100px;
    object-fit: cover;
    // border:2px solid $btn-color
  }

  div {
    margin-left: 1em;

    h3 {
      font-size: 0.875em;
      margin-bottom: 0.3em;
      font-weight: 600;

      span {
        font-weight: 500;
        font-size: 13px;
      }

      @media (max-width: 767px) {
        font-size: 0.8em;
      }
    }

    h4 {
      color: #aaa9ae;
      font-size: 0.7em;
      margin: 0;
    }
  }

  &.mediaUser1 {
    img {
      width: 50px;
      height: 50px;
    }

    div {
      margin-left: 0.6em;
    }
  }
}

.uFlex {
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100px;
    margin-right: 0.4em;
  }

  h5 {
    margin: 0;
    font-size: 0.9em;
  }
}

textarea.form-control {
  height: initial;
  padding-top: 10px;
}

.tTagbox {
  background-color: $white-color;
  padding: 0px 10px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  font-size: 0.875em;

  .tags {
    input {
      padding-top: 13.51px;
      padding-bottom: 13.51px;

      &::placeholder {
        color: #212529;
      }
    }
  }

  span {
    background-color: #ebeafd;
    padding: 10px;
    font-size: 0.8em;
    border-radius: 10px;
    margin-right: 0.6em;
    color: $black-color;

    svg {
      margin-left: 0.5em;
    }

    cursor: pointer;

    &:hover {
      svg {
        path {
          fill: red;
        }
      }
    }
  }
}

.mod_foot {
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;

  .btn {
    &.btnbig {
      min-width: 167px;
    }
  }

  div {
    .btn {
      background-color: #ebeafd;
      border-color: #ebeafd;
      margin-right: 0.8em;
    }
  }
}

.aftContent {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  &aftContent::-webkit-scrollbar-track {
    display: none;
  }

  &aftContent::-webkit-scrollbar-thumb {
    display: none;
  }

  img {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    object-fit: cover;
    // border:2px solid $btn-color
  }

  .comBox {
    margin-left: 0.4em;

    .repCommnet {
      background-color: #edf2f6;
      // padding: 14px;
      padding: 5px 10px 14px 14px;
      // padding-top: 7px !important;
      border-radius: 10px;
      min-width: 140px;
      max-width: 100% !important;

      .menuDots {
        display: flex;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      h3 {
        font-size: 16px;
        color: $black-color;
        margin-bottom: 5px;
      }

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        margin: 0;
      }
    }

    .replyBox {
      margin-top: 0.8em;
      color: #87858e;
      font-size: 12px;

      .replyUser {
        font-size: 14px;
        display: inline-flex;

        svg {
          width: 30px;
          height: 30px;
        }
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.badgeBox {
  font-size: 0.9em;
}

.chatsuggestions {
  .w-100__suggestions {
    position: absolute !important;
    top: initial !important;
    bottom: 45px !important;
    max-height: 100px;
    overflow-y: auto;
    /* You may need to adjust other styles like width, height, etc. depending on your layout */
  }
}

.replBox {
  margin-top: 1em;
  display: flex;
  align-items: center;

  .w-100__control {
    width: calc(100% - 40px);

    textarea {
      padding-right: 40px !important;
    }
  }

  // border-radius: 12px;
  // border: 1px solid #e4e4e4;
  // padding: 10px;
  // background: #fff;
  position: relative;
  z-index: 1;

  img {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    object-fit: cover;
    margin-right: 10px;
    // border:2px solid $btn-color
  }

  a {
    position: absolute;
    right: 10px;
    // top: 10px;
    top: calc(50% - 15px);

    svg {
      width: 30px;
      height: 30px;
    }
  }

  // .customformcontrol{
  //   .w-100__control{
  //     min-height: 48px!important;
  //   }
  //   textarea , input{
  //     border-radius: 40px!important;
  //     border: 1px solid #e4e4e4!important;
  //     padding: 10px!important;
  //     background: #fff!important;
  //     padding-right: 45px!important;
  //     height: 48px!important;
  //   }
  // }
  .form-control {
    // border-color: transparent;
    border-radius: 40px;
    border: 1px solid #e4e4e4;
    padding: 10px;
    background: #fff;
    padding-right: 45px;
  }
}

.userInfo {
  text-align: center;

  .bgImg {
    height: 142px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .usePOs {
    position: relative;
    margin: -50px auto 1.3em;
    width: 91px;
    height: 91px;
    border: 3px solid $white-color;
    border-radius: 50%;
    overflow: hidden;
    background: $white-color;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);

    .userImg {
      width: 91px;
      height: 91px;
      object-fit: cover;
      // border-radius: 100px;
      // border:3px solid $white-color;
      // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    }

    svg {
      color: $btn-color;
      position: absolute;
      bottom: 4px;
      right: 0;
      background-color: $white-color;
      border-radius: 100px;
    }
  }

  h2 {
    font-size: 1.125em;
    font-weight: 700;
    margin-bottom: 0.1em;
  }

  p {
    font-size: 1em;
    color: #b9b9b9;
  }

  h3 {
    display: flex;
    justify-content: space-between;
    font-size: 1em;
    color: $black-color;

    span {
      color: #b9b9b9;
    }
  }
}

.reqBadge {
  font-size: 1em;
  font-weight: 700;
  display: flex;
  align-items: center;

  .bg-secondary {
    margin-left: 0.8em;
    border-radius: 100px;
    background-color: $light-color !important;
    color: $btn-color;
  }
}

.listUser {
  margin-bottom: 1.3em;

  .width {
    width: 10px;
  }
}

.btnFlex {
  margin-top: 1em;
  display: flex;

  .btn {
    flex-grow: 1;
    font-size: 0.875em;
    height: 46px;

    &.btn-primary {
      background-color: $primary-color;
    }

    &.btn-secondary {
      background-color: $light-color;
      border-color: $light-color;
      color: #343a40;
      font-weight: 500;

      &:hover {
        background-color: $btn-color;
        color: $white-color;
      }
    }
  }
}

.networkList {
  margin-bottom: 1em;
  border-color: transparent;
  border-radius: 15px;
  padding: 15px;

  @media (max-width: 767px) {
    padding: 15px 8px;
  }

  .btnFlex {
    margin-top: 0;

    .btn {
      width: 110px;
    }

    .width {
      width: 10px;
    }

    @media (max-width: 767px) {
      font-size: 0.8em;

      .btn {
        width: 75px;
        min-width: 75px;
        height: 40px;
      }

      .width {
        width: 5px;
      }
    }
  }

  &.networkList1 {
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    h3 {
      margin-bottom: 0;

      span {
        font-weight: 400;
        color: $black-color;
        opacity: 0.6;
      }

      @media (max-width: 767px) {
        font-size: 0.7em;
      }
    }

    .btnFlex {
      h4 {
        color: $black-color;
        opacity: 0.6;
        font-size: 0.8em;
      }

      @media (max-width: 767px) {
        min-width: 50px;
        justify-content: flex-end;
      }
    }
  }

  @media (max-width: 767px) {
    &.networkList2 {
      .d-flex {
        .mediaUser {
          margin-bottom: 1em;

          @media (max-width: 767px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.memberCard {
  background-color: $white-color;
  border-radius: 15px;
  padding: 16px 20px;
  // margin-bottom: 1em;
  height: 100%;

  h2 {
    font-size: 1.063em;
    font-weight: 700;
    color: $black-color;
  }

  p {
    font-size: 0.875em;
    color: $black-color;
    opacity: 0.7;
    word-break: break-word;
    min-height: 43px;
  }

  h5 {
    font-size: 0.875em;
    color: $black-color;
    opacity: 0.7;
  }

  .cardMed {
    display: flex;
    align-items: center;

    img {
      width: 45px;
      height: 45px;
      border: 2px solid $white-color;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      object-fit: cover;

      &:nth-child(1) {
        margin-right: -15px;
      }
    }

    .medCount {
      width: 45px;
      height: 45px;
      border: 2px solid $white-color;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.8em;
      background-color: $btn-color;
      color: $white-color;
      margin-left: -15px;
    }
  }
}

.projectCard {
  background-color: $white-color;
  border-radius: 15px;
  padding: 16px 30px 10px 20px;
  // margin-bottom: 1em;
  position: relative;
  z-index: 1;
  height: 100%;

  .menuDostBox.dropdown {
    position: absolute;
    top: 6px;
    right: -5px;

    button {
      svg {
        path {
          fill: #afafaf;
        }
      }
    }
  }

  h2 {
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0.8em;
  }

  p {
    font-size: 0.875em;
    color: $black-color;
    opacity: 0.7;
    min-height: 63px;
  }

  h5 {
    font-size: 0.875em;
    color: $black-color;
    opacity: 0.7;
  }

  img {
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
  }

  .cardMed {
    margin-bottom: 1em;
    display: flex;
    // align-items  : center;

    .prRight {
      padding-left: 0.8em;
    }

    .tgFlex {
      span {
        border-radius: 6px;
        background-color: rgba(99, 100, 255, 0.11);
        font-size: 0.8em;
        margin-right: 0.4em;
        color: #6364ff;
        padding: 3px 6px;
        display: inline;
      }
    }

    .tgFlexPassion {
      display: flex;
      flex-wrap: wrap;

      span {
        border-radius: 6px;
        background-color: rgba(99, 100, 255, 0.11);
        font-size: 0.8em;
        margin-right: 0.4em;
        margin-bottom: 0.4em;
        color: #6364ff;
        padding: 1px 5px;
      }
    }
  }

  a {
    color: $btn-color;
    font-size: 0.875em;
    font-weight: 600;
  }
}

.pasDetails {
  h2 {
    color: #231f2f;
    font-size: 22px;
    margin-bottom: 0.5em;
    font-weight: 700;
  }

  p {
    opacity: 0.8;
    line-height: 1.5;
    word-spacing: 0.1rem;
    font-size: 1em;
  }

  .linkUrl {
    display: block;
    margin-top: 1.5em;
  }

  h3 {
    color: #231f2f;
    font-size: 20px;
    margin-bottom: 0.6em;
    font-weight: 700;
    margin-top: 1.5em;
  }

  .tgFlex {
    margin-bottom: 1.5em;

    span {
      border-radius: 6px;
      background-color: rgba(99, 100, 255, 0.11);
      font-size: 0.875em;
      margin-right: 0.4em;
      color: #6364ff;
      padding: 5px 15px;
      display: inline;
    }
  }

  .pdfBox {
    display: flex;
    align-items: center;

    div {
      background-color: $white-color;
      border-radius: 12px;
      padding: 8px;
      margin-right: 0.6em;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);

      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}

.cardProfile {
  border-radius: 12px;
  background-color: $white-color;
  overflow: hidden;
  padding-bottom: 2em;

  .probg {
    position: relative;

    img {
      width: 100%;
      height: 250px;
      border-radius: 12px;
      object-fit: cover;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      color: black;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.452);
      border-radius: 12px;
    }

    .btn {
      position: absolute;
      z-index: 3;
      top: 15px;
      right: 15px;
      background-color: rgba(255, 255, 255, 0.2);
      border-color: rgba(255, 255, 255, 0.2);
      font-size: 0.875em;
    }
  }

  .profileimguser {
    h2 {
      max-width: 140px;
      min-width: 140px;
      font-size: 18px;
      color: $black-color;
      font-weight: 600;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }

  .userDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -120px;
    z-index: 2;
    position: relative;

    @media (max-width: 767px) {
      margin-top: -30px !important;
    }

    margin-bottom: 2em;

    .imgBox {
      border-radius: 170px;
      border: 2px solid #fff;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
      width: 130px;
      height: 130px;
      min-width: 130px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: $white-color;

      @media (max-width: 767px) {
        width: 90px;
        height: 90px;
        min-width: 90px;
      }

      img {
        width: 120px;
        height: 120px;
        min-width: 120px;
        border-radius: 100px;
        object-fit: cover;

        @media (max-width: 767px) {
          width: 80px;
          height: 80px;
          min-width: 80px;
        }
      }

      span {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
      }
    }

    .userRight {
      padding-left: 0.6em;
      text-align: center;
      align-self: self-end;
      width: calc(100% - 130px);

      @media (max-width: 767px) {
        width: calc(100% - 90px);
      }

      .profilelink {
        font-size: 14px;
        color: #aaa9ae;

        &.profileactive {
          color: $btn-color;
          font-weight: 600;
        }

        &:hover {
          color: $btn-color !important;
        }
      }

      h2 {
        color: $white-color;
        font-size: 0.875em;
      }

      div {
        span {
          margin-right: 0.8em;
          font-size: 0.875em;

          svg {
            margin-right: 0.2em;
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;
          display: flex;

          &.menuDostBox {
            .dropdown-menu {
              display: none;

              &.show {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .userDetailsPass {
    display: flex;
    align-items: center;
    margin-top: -50px;
    z-index: 2;
    position: relative;

    @media (max-width: 767px) {
      margin-top: -30px;
    }

    margin-bottom: 2em;

    .imgBox {
      border-radius: 170px;
      border: 2px solid #fff;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
      width: 100px;
      height: 100px;
      min-width: 100px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: $white-color;

      img {
        width: 90px;
        height: 90px;
        min-width: 90px;
        border-radius: 100px;
        object-fit: cover;
      }

      span {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
      }
    }

    .userRight {
      padding-left: 0.6em;

      h2 {
        color: $white-color;
        font-size: 0.875em;
      }

      div {
        span {
          margin-right: 0.8em;
          font-size: 0.875em;

          svg {
            margin-right: 0.2em;
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;
          display: flex;
        }
      }
    }
  }

  .form-label {
    font-size: 0.875em;
    font-weight: 600;
  }

  .UpdateBtn {
    margin: 0 15px;

    .btn {
      width: 100%;
      max-width: 500px;
    }
  }

  .mbPad {
    padding: 15px;

    @media (max-width: 767px) {
      .mb-3.row {
        margin-bottom: 0 !important;
      }

      .col-lg-6 {
        div {
          margin-bottom: 1em;
        }
      }
    }
  }
}

.terms {
  h2 {
    color: #231f2f;
    font-size: 1.5em;
  }

  p {
    opacity: 0.7;
  }

  h3 {
    color: $btn-color;
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 1em;

    span {
      position: relative;
      color: $black-color;
      z-index: 2;

      &::after {
        content: "";
        position: absolute;
        bottom: 4px;
        width: 80px;
        height: 5px;
        left: 1px;
        z-index: -1;
        background-color: $btn-color;
      }
    }
  }

  ul {
    margin-bottom: 1em;

    li {
      display: flex;

      p {
        margin-left: 0.5em;
      }

      svg {
        color: $btn-color;
      }
    }
  }
}

.centerSearch {
  text-align: center;
  width: 100%;
  max-width: 550px;
  margin: 0 auto 1.5em;

  h1 {
    font-size: 1.25em;
    margin-bottom: 1em;
  }

  .serchBox {
    position: relative;

    svg {
      position: absolute;
      left: 14px;
      top: 14px;
      width: 25px;
    }

    .form-control {
      border-radius: 100px;
      padding-left: 3.2em;
      height: 60px;
    }
  }
}

.tuLabel {
  color: $grey-color;
  font-weight: 600;

  &.tuLabel1 {
    font-weight: 400;
  }
}

.progress {
  border-radius: 0;

  .progress-bar {
    &.bg-primary {
      background-color: $btn-color !important;
    }

    &.bg-warning {
      background-color: #ffd023 !important;
    }
  }
}

.menuDostBox {
  // border: 2px solid black;
  .dropdown-toggle {
    &::after {
      display: none;
    }

    svg {
      path {
        fill: $black-color;
      }
    }
  }

  .dropdown-item {
    padding: 0.3em 1em;
    color: rgba(177, 177, 177, 1);
    font-size: 12px;

    &.activeColor {
      color: #7b76f1;
    }
  }

  .dropdown-menu.show {
    // left: -120px !important;
    box-shadow: 0px 0px 18px 0px #7d7d7d26;
    border-radius: 12px 0px 12px 12px;
    border-color: rgba(123, 118, 241, 0.2);
    left: initial !important;
    right: 0 !important;
    --bs-dropdown-min-width: 6rem;
  }

  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

.imagesDel {
  // margin-top: 1.5em;
  padding-top: 1.5em;
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  // overflow-x     : auto;
  // scrollbar-width: thin;
  // scrollbar-color: #090119 #f9f9f9;

  .imgItems {
    position: relative;

    img {
      width: 84px;
      min-width: 84px;
      height: 52px;
      border-radius: 6px;
      object-fit: contain;
      background: #fff;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 13%);
    }

    video {
      width: 84px;
      min-width: 84px;
      height: 100%;
      border-radius: 6px;
      object-fit: contain;
      background: #fff;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 13%);

      svg {
        &.playvideo {
          position: absolute;
          // top: calc(50% - 15px);
          // left: 0px;
          // right: 0px;
          margin: auto;
          color: $white-color;
          width: 24px;
          height: 24px;
          background: rgb(0 0 0 / 40%);
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    a {
      position: absolute;
      right: -11px;
      top: -10px;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    svg {
      &.playIconVideo {
        position: absolute;
        color: $white-color;
        width: 30px;
        height: 30px;
        background: rgb(0 0 0 / 40%);
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.networkTabs {
  margin-bottom: 2em;
  display: flex;
  justify-content: center;

  div {
    background-color: $white-color;
    border-radius: 10px;
  }

  a {
    display: inline-block;
    border-radius: 10px;
    padding: 0.8em 2em;
    background-color: $white-color;

    .badgecustom {
      background: $btn-color;
      color: #fff;
      padding: 2px 6px;
      display: inline-flex;
      font-size: 10px;
      border-radius: 2px;
    }

    &.active {
      background-color: $btn-color;
      color: $white-color !important;

      .badgecustom {
        background: #fff;
        color: #000;
      }
    }

    // &.active,
    // &:hover {
    //   background-color: $btn-color;
    //   color: $white-color !important;
    // }
  }
}

.otp-container {
  display: flex;
  gap: 0.8rem;
  justify-content: center;
}

.otp-input {
  width: 4rem;
}

.error {
  color: red;
}

.header_user_image {
  position: relative;
  display: flex;
  gap: 1rem;
  cursor: pointer;

  img {
    object-fit: cover;
    // border:2px solid $btn-color
  }
}

.header_dropdown {
  position: absolute;
  padding: 0.7rem 0;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
  background-color: #fff;
  top: 105%;
  /* Position the dropdown below the toggle button */
  right: 0;
  display: flex;
  flex-direction: column !important;
  z-index: 11;

  .flex_item {
    cursor: pointer;
    display: flex;
    gap: 0.7rem;
    align-items: center;
    padding: 0.4rem 1rem;

    svg {
      width: 22px;
      height: 22px;
    }

    &:hover {
      background-color: #7b76f1;
    }
  }
}

.user_dropdown {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  height: 10rem;
  right: 0;
  bottom: -10%;

  .drop_down_list {
    &:hover {
      background-color: #a09df7;
      cursor: pointer;
    }
  }
}

.ellipseDiv_parent {
  position: relative;

  img {
    border: none;
  }
}

.ellipseDiv {
  width: 22px;
  position: absolute;
  right: -20%;
  top: -20%;
  border-radius: 50%;
  // background-color: #ffffff94;
  background-color: $white-color;
  border: 1px solid #b96df4;
  box-sizing: border-box;
  height: 22px;
  font-size: 11px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.ellipseDivnotify {
    border: 1px solid $primary-color;
  }

  // padding         : 2px 0 0 25%;
}

.loaderdiv {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 111;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mutualCard {
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 2em;

  h2 {
    font-size: 1.2em;
    color: $btn-color;
    font-weight: 600;
    margin-bottom: 1.3em;
  }

  ul {
    li {
      display: flex;
      align-items: center;
      gap: 1em;

      &:not(:last-child) {
        margin-bottom: 1.5em;
      }

      img {
        width: 55px;
        height: 50px;
        border-radius: 100px;
        object-fit: cover;
      }

      h3 {
        font-size: 1em;
        color: $black-color;
        font-weight: 600;
        margin-bottom: 0.1em;
      }

      p {
        margin: 0;
        font-size: 0.75em;
        opacity: 0.6;
      }
    }
  }
}

.link {
  cursor: pointer;
}

.passwordPos {
  position: relative;

  .form-control {
    padding-right: 3.4em;
  }

  svg {
    position: absolute;
    top: 16px;
    right: 19px;
    font-size: 1.2em;
    cursor: pointer;
  }
}

.scroll {
  //
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: none !important;

  &::-webkit-scrollbar {
    width: 0;
  }

  &aftContent::-webkit-scrollbar-track {
    display: none;
  }

  &aftContent::-webkit-scrollbar-thumb {
    display: none;
  }
}

.chatUserList {
  margin-top: 0.5rem;
  border: 1px solid #cccccc5e;
  border-radius: 0.5rem;
  padding: 0.5rem 0;

  li {
    padding: 0.3rem 1rem 0 1rem;
    color: #666666;

    &:hover {
      background-color: #0d6efd;
      cursor: pointer;
      color: white;
    }
  }
}

.dots {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2rem;
  padding: 0 1rem 1.25rem 1rem;

  .dot {
    display: inline-block;
    background-color: #7b76f1;
    border-radius: 50%;
    margin-right: 8px;

    &-small {
      width: 8px;
      height: 8px;
      animation: typing 1s linear infinite;
    }

    &:nth-child(1) {
      animation-delay: 0.1s;
    }

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.5s;
    }
  }
}

@keyframes typing {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 20px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes typing-big {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 40px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.pointer {
  &:hover {
    cursor: pointer !important;
    input {
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
  }
}

.sliderBox {
  margin-bottom: 1em;

  .carousel-inner {
    background-color: #f1f1f1;

    .carousel-item {
      img {
        height: 500px;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &:hover {
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      opacity: 1;
    }
  }

  .carousel-control-next-icon {
    opacity: 0;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
    opacity: 0;
  }

  .carousel-indicators {
    display: none;
  }

  .carousel-indicators [data-bs-target] {
    background-color: #000;
  }
}

.replyUser {
  margin-left: 1em;
}

.des-shadow {
  background-color: $white-color;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none !important;
}

.headersearch {
  .form-control {
    padding-right: 30px;
  }

  svg {
    top: 16px;
    right: 12px;
  }

  @media only screen and (max-width: 767.98px) {
    display: none;
  }
}

.postlist {
  margin-bottom: 10px;
  border-color: transparent;
  border-radius: 15px;
  padding: 15px;
  border: 1px solid #efefef;
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  h2 {
    font-size: 0.875em;
    margin-bottom: 0;
    font-weight: 500;
    margin-left: 13px;
  }
}

.resendotp {
  font-weight: 600;
  color: #7b76f1;
  text-decoration: underline;
  font-size: 14px;
}

.search-dropdown {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  width: 100%;
  max-height: 10rem;
  position: absolute;
  top: 100%;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  overflow-x: hidden;
  font-size: 12px;

  span {
    padding: 10px;

    strong {
      display: block;
    }

    &:hover {
      cursor: pointer;
      color: #fff;
      background: $btn-color;
    }

    &.drop_down_list:not(:last-child) {
      border-bottom: 1px solid #efefef;
      padding-bottom: 10px;
    }
  }
}

.detailcontentright {
  // overflow       : auto;
  // scrollbar-width: thin;
  // scrollbar-color: #090119 #f9f9f9;
  .detailcontentrightinner {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #090119 #f9f9f9;
    max-height: 80vh;
  }

  @media only screen and (max-width: 1199px) {
    height: 450px !important;
  }
}

.maxheight80vh {
  height: 80vh;

  @media only screen and (max-width: 1199px) {
    height: 450px;
  }
}

.modal_post_detail {
  .modal-content {
    overflow: hidden;
    border-radius: 12px;

    .closeicon {
      position: absolute;
      right: 2px;
      z-index: 1;
      width: 30px;
      height: 30px;
      top: 2px;
      opacity: 1;
    }

    .closebutton {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 1;
      width: fit-content;
      height: fit-content;
      opacity: 1;
    }

    .detailimgleft {
      .carousel-inner {
        height: 100%;
      }
    }
  }
}

textarea {
  resize: none !important;
}

.nodatafound {
  .nodatafoundinner {
    border-radius: 15px;
    padding: 20px 16px 16px;

    // background:$white-color;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    h3 {
      font-size: 24px;
      font-weight: 600;
      color: $btn-color;
      margin: 10px 0px 10px;
    }
  }
}

.carouselpassion {
  height: 400px;
  text-align: center;
  border-radius: 15px;
  overflow: hidden;
  background-color: #e9e9e9;

  .carousel-control-next {
    right: 15px;
  }

  .carousel-control-prev {
    left: 15px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    top: calc(50% - 35px);
    width: 40px;
    height: 40px;
    background: $btn-color;
    border-radius: 50%;
    padding: 10px;
  }

  .carousel-inner {
    height: 100%;

    .carousel-item {
      img {
        max-height: 100%;
      }
    }
  }
}

.customformcontrol {
  max-width: 100% !important;
}

.cover-photo-placeholder {
  width: 100%;
  /* Adjust width as needed */
  height: 250px;
  /* Adjust height as needed */
  background-color: #f0f0f0 !important;
  /* Light gray background */
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  font-size: 16px;
  opacity: 0.8;
  font-weight: 500;
  color: #b9b9b9;
}

.createnewpostbtn {
  justify-content: space-between;
  display: flex;
  align-items: center;
  background: $white-color !important;
  padding: 13px;
  border-radius: 12px;
  color: #b9b9b9;
  font-size: 14px;
  box-shadow: 0px 0px 10px rgba($black-color, 0.07);

  svg {
    width: 44px;
    height: 44px;
    background: $btn-color;
    border-radius: 10px;
    padding: 6px;
    color: $white-color;
  }
}

.recentchat {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;

  .recentchatimg {
    width: 45px;
    height: 45px;
    min-width: 45px;
    border-radius: 50%;
    position: relative;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    .badgechat {
      &.chatonline {
        background: #36ce00;
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        bottom: 3px;
        right: 3px;
      }
    }
  }

  .recentchatcontent {
    padding-left: 5px;
    width: calc(100% - 45px);

    h4 {
      font-size: 14px;
      color: $black-color;
      font-weight: 500;
    }

    span {
      color: #727272;
      font-size: 11px;
      min-width: 45px;
      text-align: end;
    }

    p {
      color: #8a8a8a;
      font-size: 12px;
      margin-top: 3px;
    }
  }
}

.AddGroupIcon {
  img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    box-shadow: 0px 0px 20px 0px rgba($black-color, 0.15);
    border: 4px solid $white-color;
    object-fit: cover;
    margin: auto;
  }

  label {
    color: $btn-color;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }
}

.notification_dropdown {
  position: absolute;
  z-index: 1;
  min-width: 22em;
  right: 0px;
  padding: 15px;
  background: $white-color;
  box-shadow: 0px 0px 10px rgba($black-color, 0.07);
  border-radius: 20px 0px 20px 20px;
  top: 100%;

  @media only screen and (max-width: 575px) {
    min-width: 15em;
    padding: 12px;
  }

  @media only screen and (max-width: 450px) {
    min-width: 13em;
    left: 0px;
    right: initial;
    border-radius: 0px 20px 20px 20px;
  }

  .seeallnotify {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: $btn-color !important;
    padding: 12px 0px !important;
  }

  .notificationlist {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
    position: relative;
    z-index: 1;

    &:not(:last-child) {
      &:after {
        content: "";
        background: $light-color;
        height: 1px;
        width: calc(100% - 60px);
        position: absolute;
        right: 0px;
        bottom: -10px;
      }
    }

    .notificationimg {
      width: 50px;
      height: 50px;
      min-width: 50px;
      border-radius: 50%;

      @media only screen and (max-width: 575px) {
        width: 40px;
        height: 40px;
        min-width: 40px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .notificationcontent {
      width: calc(100% - 50px);
      margin-left: 10px;
      display: flex;
      align-items: center;

      h3 {
        font-size: 12px;
        color: $black-color;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 1.25;
        padding-right: 10px;

        span {
          font-weight: 400;
          opacity: 0.7;
        }

        @media only screen and (max-width: 575px) {
          font-size: 12px;
        }
      }

      .notificationtime {
        color: $black-color;
        opacity: 0.4;
        font-size: 10px;
        white-space: nowrap;
        // line-height: 1;

        @media only screen and (max-width: 575px) {
          font-size: 11px;
        }
      }
    }
  }
}

.accept-decline {
  a {
    padding: 3px 8px !important;
    font-size: 10px !important;
    margin-right: 0.75em;

    &.acceptbtn {
      background: $btn-color;
      color: $white-color !important;
      border: 1px solid $btn-color;

      &:hover {
        background: transparent;
        color: $btn-color !important;
        border: 1px solid $btn-color;
      }
    }

    &.rejectbtn {
      background: transparent;
      color: $btn-color !important;
      border: 1px solid $btn-color;

      &:hover {
        background: $btn-color;
        color: $white-color !important;
        border: 1px solid $btn-color;
      }
    }
  }
}

.profilecard {
  padding: 15px;
  background-color: $white-color;
  border-color: $white-color;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
}

.cardprofile {
  padding: 15px;
  border-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 12px;

  .cardprofiletitle {
    font-size: 14px;
    color: #111111;
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebebeb;

    &.cardpassiontitle {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .cardsubtitle {
    font-size: 14px;
    color: $black-color;
    font-weight: 500;
  }

  .profilebio {
    color: #8a8a8a;
    font-size: 12px;
  }

  .profileinfo,
  .otherprofileinfo {
    span {
      color: $black-color;
      font-size: 12px;

      svg {
        min-width: 20px;
        font-size: 17px;
        color: #6364ff;
        margin-right: 5px;
      }
    }
  }

  .lastphotos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .lastphotosgrid {
      flex-basis: 33.33%;
      border: 1px solid $white-color;

      img {
        height: 89px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.profilecard {
  label {
    &.form-label {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 3px;
    }
  }
}

.UpdateBtn {
  max-width: 496px;
}

.profilepiccard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 148px;
    height: 148px;
    border: 4px solid $white-color;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 20px 0px rgba($black-color, 0.15);
  }

  span {
    font-size: 16px;
    color: $btn-color;
    font-weight: 600;
    cursor: pointer;
  }
}

.nodatafound2 {
  padding: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  background: #fff;
}

.chatmsgtime {
  color: #727272;
  font-size: 11px;
  min-width: 45px;
  text-align: end;
}

.badge-chat {
  background-color: rgba($btn-color, 0.1) !important;
  border-radius: 100px;
  color: $btn-color;
  margin-left: 0.8em;
  font-size: 10px;
  height: 19px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.acceptButton {
  width: 4rem !important;
  height: 2rem !important;
  background-color: rgba(0, 255, 0, 0.295);
  padding: 0.25rem;
  margin-left: 1rem;
}

.rejectButton {
  width: 4rem !important;
  height: 2rem !important;
  background-color: #f1f1f1 !important;
  border: 1px solid #f1f1f1 !important;
  margin-left: 1rem;
  padding: 0.25rem;
  color: #000;
}

.compare-search {
  position: relative !important;
  width: 100%;

  .form-control {
    border-radius: 100px;
    background-color: #f2f3f5;
    border-color: #f2f3f5;
    padding-left: 2.5em;
  }

  .search-image {
    position: absolute !important;
    top: 50%;
    left: 0.75rem;
    transform: translate(0, -50%);
  }
}

.search-college-dropdown {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  width: 100%;
  max-height: 10rem;
  position: absolute;
  top: 100%;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  // overflow-x    : hidden;
  font-size: 12px;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
  }

  &aftContent::-webkit-scrollbar-track {
    display: none;
  }

  &aftContent::-webkit-scrollbar-thumb {
    display: none;
  }

  span {
    padding: 10px;

    strong {
      display: block;
    }

    &:hover {
      cursor: pointer;
      color: #fff;
      background: $btn-color;
    }

    &.drop_down_list:not(:last-child) {
      border-bottom: 1px solid #efefef;
      padding-bottom: 10px;
    }
  }
}

// .profile-border{
//   border:1px solid #7B76F1
// }
/* Chat Css */
/*FAQ CSS Start*/
.faqs {
  .accordion {
    .accordion-item {
      border: none;
      margin-bottom: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
      border-radius: 10px;

      h2 {
        &.accordion-header {
          .accordion-button {
            background: rgba(123, 118, 241, 0.38);
            border-radius: 10px;
            outline: none;
            box-shadow: none !important;
            font-size: 17px;
            color: #101130;
            font-weight: 500;
          }
        }
      }
    }
  }
}

/*FAQ CSS Ends*/
/*Resources CSS Start*/
.resources {
  ul {
    &.resourceslist {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        &:hover {
          a {
            opacity: 0.75;
            color: $btn-color;
          }
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        a {
          font-size: 16px;
          color: $btn-color !important;
          display: inline-flex;
          text-decoration: none;
          font-weight: 500;

          svg {
            font-size: 21px;
            min-width: 22px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

/*Resources CSS Ends*/
.radionsignup {
  border-width: 1px;
  border-style: solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
  background-color: $white-color;
  border-radius: 12px;
  padding: 12px 10px;
  position: relative;
  margin: 0px 10px 10px;
  cursor: pointer;

  span {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    font-size: 20px;
    position: absolute;
    color: $btn-color;
    bottom: -8px;
    right: -5px;
    background: #fff;
    border-radius: 50%;
  }
}

.listsuggestedpages {
  .mediaUser {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.btnfollow {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: $white-color !important;
  font-size: 12px !important;
  height: 29px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  min-width: 75px;

  svg {
    font-size: 18px;
  }
  &:hover {
    background-color: transparent !important;
    border-color: $primary-color !important;
    color: $primary-color !important;
  }
}
.width50height50 {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
}
.btnfollowoutline {
  background-color: transparent !important;
  border-color: $primary-color !important;
  color: $primary-color !important;
  font-size: 12px !important;
  height: 29px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  min-width: 75px;
  svg {
    font-size: 18px;
  }
  &:hover {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: $white-color !important;
  }
}

.pageadd {
  .mediaUser {
    div {
      a {
        color: #000000;
      }
    }
  }

  .cardprofiletitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .addpage {
      width: 33px;
      height: 33px;
      padding: 0px;
    }
  }

  .pagefooter {
    border-top: 1px solid #ebebeb;
    padding-top: 15px;

    a {
      padding: 0px 10px;
      color: $btn-color;
      font-size: 14px;
    }
  }
}

.pagecard {
  overflow: visible;

  .userDetails {
    .userRight {
      transform: translateY(1px);

      .userLeft {
        a {
          font-size: 16px;
          color: #aaa9ae;

          span {
            color: $btn-color;
            margin-right: 0;
            font-size: 16px;
          }
        }
      }

      h2 {
        font-size: 20px;
        color: $black-color;
        font-weight: 600;
        margin-bottom: 5px;
      }
    }
  }
}

.allpagescard {
  background-color: $white-color;
  padding: 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  align-items: center;
  height: 100%;

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
  }

  .pagecardright {
    width: calc(100% - 80px);
    padding-left: 14px;

    h2 {
      font-size: 13px;
      color: $black-color;
      font-weight: 600;
      margin-bottom: 3px;
    }

    p {
      font-size: 12px;
      color: #8a8a8a;
      margin-bottom: 0px;
    }

    a {
      font-size: 12px;
      color: $btn-color;
      font-weight: 600;

      svg {
        font-size: 18px;
      }
    }
  }
}

.suggestionscard {
  border-radius: 15px;
  overflow: hidden;
  height: 100%;

  .coverimg {
    img {
      width: 100%;
      height: 70px;
      object-fit: cover;
    }
  }

  .suggestionscontent {
    text-align: center;
    margin-top: -40px;
    padding: 0px 15px 15px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid $white-color;
    }

    h3 {
      font-size: 13px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    p {
      font-size: 12px;
      color: #aaa9ae;
      margin-bottom: 7px;
    }

    .btmbtn {
      p {
        margin-bottom: 7px;
      }
    }
  }
}

.minheight65vh {
  min-height: 80vh;
}

.sharemodal {
  .modal-content {
    height: auto !important;

    .sharewithgrid {
      .sharegridmain {
        margin-bottom: 20px;

        @media only screen and (min-width: 1024px) {
          width: 20%;
        }

        .sharegridinner {
          text-align: center;
          display: block;

          &:hover {
            .shareicons {
              background: rgba($btn-color, 1);
              color: $white-color;
            }
          }

          .shareicons {
            width: 60px;
            height: 60px;
            background: rgba($btn-color, 0.1);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 20px;
            color: $secondary-color;
          }

          h5 {
            font-size: 14px;
            font-weight: 400;
            margin: 10px 0px 0px;
            color: $secondary-color;
          }
        }
      }
    }

    .copysharelink {
      font-size: 18px;
      position: absolute;
      top: 8px;
      right: 17px;
      color: $btn-color;
    }
  }
}
.countyList.locationlist_box {
  ul {
    justify-content: center;
    li {
      width: auto;
      display: inline-block;
      a {
        padding: 0px 15px !important;
        min-width: 100px;
      }
    }
  }
}
.addmorecompare_box {
  background-color: #ffffff;
  padding: 30px 20px;
  border-radius: 20px;
  text-align: center;
  svg {
    font-size: 40px;
    margin-bottom: 10px;
    color: #8a8a8a;
  }
  h4 {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    color: #8a8a8a;
  }
}
.collegedtl_sec {
  padding: 70px 0px;
  .collegedtl_img {
    background: rgba(14, 14, 14, 0.1);
    text-align: center;
    padding: 25px;
    border-radius: 12px;
    img {
      max-width: 100px;
      margin-bottom: 15px;
    }
    h4 {
      font-size: 16px;
      font-weight: 600;
      color: #1d1d1d;
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #777;
      margin-bottom: 0px;
    }
  }
  .collegedtl_content {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    h4.collegedtl_head {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 15px;
      border-left: 6px solid $primary-color1;
      padding-left: 10px;
    }
    h5.collegedtl_subhead {
      font-size: 18px;
      font-weight: 600;
      color: #555;
      margin-bottom: 10px;
    }
    .collegedtl_list {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dashed #999;
      padding-bottom: 10px;
      margin-bottom: 15px;
      h5 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0px;
        color: #333;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0px;
        color: #777;
      }
    }
  }
}
.searchlists {
  &:not(:last-child) {
    border-bottom: 1px solid rgb(235, 235, 235);
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}
.takeAssessment_div {
  background: $white-color;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0px 0px 30px 0px rgba($black-color, 0.07);
  margin-bottom: 20px;
  .Questionnaire_head {
    margin-bottom: 40px;
    h1 {
      font-size: 24px;
      font-weight: bold;
      color: $secondary-color;
      margin-bottom: 5px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #8a8a8a;
      margin-bottom: 0px;
    }
  }
  .AllSection_title {
    font-size: 18px;
    font-weight: 600;
    color: $secondary-color;
    padding-bottom: 12px;
    margin-bottom: 14px;
    border-bottom: 1px solid rgba($black-color, 0.1);
  }
  .stepper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
  }
  .step {
    font-size: 16px;
    font-weight: 500;
    color: #cccccc;
    display: inline-flex;
    align-items: center;
    gap: 0px 10px;
    svg {
      width: 24px;
      height: 24px;
      border: 1px solid #cccccc;
      border-radius: 50%;
      color: #ccc;
    }
    &.active,
    &.completed {
      color: $secondary-color;
      svg {
        width: 24px;
        height: 24px;
        border: 1px solid $btn-color;
        border-radius: 50%;
        color: $white-color;
        background: $btn-color;
      }
    }
  }
  .questionnaire_div {
    .questionnaire_box {
      border: 1px solid rgba($black-color, 0.1);
      border-radius: 10px;
      padding: 0px;
      margin-bottom: 20px;
      .questionnaire_header {
        padding: 16px;
        .question_subtitle {
          font-size: 12px;
          font-weight: 500;
          color: $btn-color;
          margin-bottom: 10px;
        }
        .question_title {
          font-size: 15px;
          font-weight: 600;
          color: $secondary-color;
          margin-bottom: 0px;
        }
      }
      .questioncheckBox_form {
        border-top: 1px solid rgba($black-color, 0.1);
        padding: 24px 16px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px 50px;
        .form-check {
          .form-check-input {
            border: 1px solid #ccc;
            border-radius: 6px;
            width: 20px;
            height: 20px;
            outline: 0px;
            box-shadow: none;
            margin-top: 2px;
            &:checked {
              background-color: $btn-color;
              border: 1px solid $btn-color;
              ~ {
                .form-check-label {
                  color: $secondary-color;
                }
              }
            }
          }
          .form-check-label {
            font-size: 14px;
            font-weight: 400;
            color: #8a8a8a;
            position: initial !important;
          }
        }
      }
    }
  }
  .navigation-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      min-width: 130px;
    }
  }
  .QuestionnResult_box {
    border: 1px solid rgba($black-color, 0.1);
    border-radius: 10px;
    padding: 16px;
    min-height: 16rem;
    .QuestionnResult_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba($black-color, 0.1);
      padding-bottom: 20px;
      margin-bottom: 20px;
      h4 {
        font-size: 18px;
        font-weight: 600;
        color: $secondary-color;
        margin-bottom: 0px;
      }
      .resultScore_badge {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: $btn-color;
        color: $white-color;
        font-size: 20px;
        font-weight: bold;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        line-height: 1;
        small {
          font-size: 10px;
          font-weight: 400;
        }
      }
    }
    h5 {
      font-size: 10px;
      font-weight: 400;
      color: $secondary-color;
      margin-bottom: 12px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: #8a8a8a;
      margin-bottom: 0px;
    }
  }
  .navigation-buttons button:disabled {
    cursor: not-allowed;
    background-color: #ddd;
  }
  .carrerReport_card {
    background: $white-color;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px rgba($black-color, 0.09);
    margin-bottom: 30px;
    .carrerReport_head {
      display: flex;
      align-items: center;
      gap: 0px 10px;
      margin-bottom: 20px;
      .Icon_img {
        background: $btn-color;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 22px;
        }
      }
      .carrerHead_cont {
        flex-grow: 1;
        h4 {
          font-size: 18px;
          font-weight: 600;
          color: $btn-color;
          line-height: 1;
          margin-bottom: 0px;
        }
        p {
          font-size: 10px;
          font-weight: 400;
          color: #8a8a8a;
          line-height: 1;
          margin-top: 8px;
          margin-bottom: 0px;
          b {
            font-weight: 500;
            color: $btn-color;
          }
        }
      }
    }
    .careerReport_box {
      border: 1px solid rgba($black-color, 0.1);
      padding: 16px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 13px 0px;
      height: 100%;
      .careerReportBox_title {
        font-size: 14px;
        font-weight: 600;
        color: $secondary-color;
        border-bottom: 1px solid rgba($black-color, 0.1);
        margin-bottom: 0px;
        padding-bottom: 14px;
        width: 100%;
      }
      .careerReport_head {
        border-bottom: 1px solid rgba($black-color, 0.1);
        margin-bottom: 0px;
        padding-bottom: 14px;
        width: 100%;
        h5 {
          font-size: 14px;
          font-weight: 600;
          color: $secondary-color;
          margin-bottom: 5px;
        }
        h6 {
          font-size: 14px;
          font-weight: 400;
          color: $secondary-color;
          margin-bottom: 0px;
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: $secondary-color;
        margin-bottom: 0px;
        position: relative;
        width: 100%;
        padding-left: 14px;
        &.dot_remove {
          padding-left: 0px;
          &:after {
            display: none;
          }
        }
        &::after {
          position: absolute;
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: $btn-color;
          left: 0px;
          top: 7px;
        }
      }
    }
  }
}
.FitReport_modal {
  .modal-dialog {
    &.modal-Width_450 {
      @media only screen and (min-width: 992px) {
        max-width: 450px;
      }
    }
    .modal-content {
      border: 0px;
      border-radius: 20px;
      padding: 30px;
      .modal-header {
        padding: 0px 0px 20px;
        border-bottom: 1px solid #d8d6de;
        h4 {
          font-size: 24px;
          font-weight: 600;
          color: $black-color;
          margin-bottom: 0px;
        }
        .modal_close {
          display: inline-flex;
          margin: 0px;
          padding: 0px;
          font-size: 24px;
          color: $black-color;
          border: 0px;
        }
      }
      .modal-body {
        padding: 20px 0px 0px;
        .reportRadio_form {
          max-height: 50vh;
          overflow-y: auto;
          .form-check {
            background: #f9f9f9;
            border-radius: 10px;
            padding: 10px 10px 10px 10px;
            margin-bottom: 13px;
            flex-direction: row-reverse;
            .form-check-input {
              flex-shrink: 0;
              margin: 0px;
              width: 20px;
              height: 20px;
              border: 1px solid #cccccc;
              background-color: $white-color;
              border-radius: 50%;
              &:checked {
                background-color: $white-color;
                background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%237B76F1%27/%3e%3c/svg%3e");
                border: 1px solid $btn-color;
              }
            }
            .form-check-label {
              flex-grow: 1;
              position: initial !important;
              h4 {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 4px;
                color: $secondary-color;
              }
              h6 {
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 0px;
                color: #8a8a8a;
                span {
                  display: inline-block;
                  font-weight: 500;
                  color: $btn-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
.videoclose {
  position: absolute;
  right: -20px;
  top: -24px;
  font-size: 25px;
  color: $white-color;
}
.modalvideo {
  .modal-content {
    border: none;
    background-color: $btn-color;
    .modal-body {
      p {
        color: $white-color;
        font-weight: 600;
        // color: rgba($black-color, 0.5);
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
}
@import "./component/sass/Chat.scss";
/* Footer Css */
@import "./component/sass/StepIndicator.scss";
/* Footer Css */
@import "./component/sass/Footer.scss";
/* Media Quary */
@import "./component/sass/MediaQuary.scss";

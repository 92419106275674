nav.sticky-top {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
}

.navbarmarginright {
    margin-right: 257px;

    @media only screen and (max-width:991.98px) {
        margin-right: 0;
    }
}

.landingpages {
    .btn-primary {
        height         : 60px;
        min-width      : 155px;
        border-radius  : 8px;
        background     : $gradient-color !important;
        color          : $white-color !important;
        border         : none !important;
        font-size      : 18px;
        font-weight    : 600;
        display        : inline-flex;
        padding        : 0px 28px;
        align-items    : center;
        justify-content: center;
        position       : relative;

        @media only screen and (max-width: 1199.98px) {
            height   : 55px;
            font-size: 16px;
        }

        @media only screen and (max-width: 767.98px) {
            font-size: 14px;
            padding  : 0px 15px;
        }

        span {
            width     : 0px;
            opacity   : 0;
            overflow  : hidden;
            transition: all 1s ease-in-out;

            @media only screen and (max-width: 1199.98px) {
                img {
                    height: 24px;
                }
            }

            @media only screen and (max-width: 767.98px) {
                img {
                    height: 20px;
                }
            }
        }

        &:after {
            content      : '';
            background   : #F4EBFF;
            height       : 68px;
            position     : absolute;
            top          : -4px;
            bottom       : -4px;
            left         : -4px;
            right        : -4px;
            z-index      : -1;
            border-radius: 8px;

            @media only screen and (max-width: 1199.98px) {
                height: 63px;
            }
        }
    }

    .btn-learnmore {
        height         : 60px;
        min-width      : 155px;
        border-radius  : 8px;
        background     : #F4EBFF !important;
        color          : $btn-color !important;
        border         : none !important;
        font-size      : 18px;
        font-weight    : 600;
        display        : inline-flex;
        align-items    : center;
        justify-content: center;

        @media only screen and (max-width: 1199.98px) {
            height   : 55px;
            font-size: 16px;
        }

        @media only screen and (max-width: 767.98px) {
            font-size: 14px;
        }

        &:hover {
            background: $btn-color !important;
            color     : $white-color !important;
        }
    }

    .btn-primary:hover,
    .btn-primary:focus,
    .btn-primary:first-child:active,
    :not(.btn-check)+.btn-primary:active {
        background: transparent;
        border    : 2px solid $btn-color;
        color     : $btn-color;

        span {
            width      : 32px;
            opacity    : 1;
            overflow   : hidden;
            margin-left: 15px;
            transition : all 1s ease-in-out;

            @media only screen and (max-width: 1199.98px) {
                margin-left: 5px;
            }
        }
    }
    .opacity01 {
        opacity: 0.1
    }
    
    .shapes {
        position: absolute;
        z-index : -1;

        @media only screen and (max-width: 991.98px) {
            img {
                max-width: 250px;
            }
        }

        &.shape3 {
            right : -20px;
            bottom: 0px;
        }

        &.shape1 {
            top : 60px;
            left: 50px;

            @media only screen and (max-width: 767.98px) {
                top : 30px;
                left: 20px;
            }
        }

        &.shape2 {
            bottom   : -50px;
            right    : -10px;
            transform: rotate(-80deg);
        }

        &.shape4 {
            bottom   : -70px;
            transform: rotate(45deg);
            z-index  : initial;
        }
    }

    .max-height-550 {
        max-height: 550px;
    }

    .text-gradient {
        font-size              : 18px;
        text-decoration        : none;
        display                : flex;
        align-items            : center;
        font-weight            : 500;
        background             : $gradient-color;
        -webkit-background-clip: text;
        color                  : transparent;

        @media only screen and (max-width: 1199.98px) {
            font-size: 16px;
        }

        @media only screen and (max-width: 767.98px) {
            font-size: 14px;
        }

        img {
            margin-left: 13px;
        }
    }

    .heading {
        h2 {
            color      : $heading-color;
            font-size  : 40px;
            font-weight: 700;

            @media only screen and (max-width: 1024px) {
                font-size: 30px;
            }

            @media only screen and (max-width: 575.98px) {
                font-size: 24px;
            }
        }

        p {
            color      : $paragraph-color2;
            font-size  : 14px;
            font-weight: 500;
            max-width  : 990px;
            margin     : 0 auto;
            line-height: 1.75;
        }
    }

    .bg-grey {
        background: #fbfbfb;
    }

    @mixin bannerbadges {
        width          : 49px;
        height         : 49px;
        background     : $gradient-color;
        display        : flex;
        align-items    : center;
        justify-content: center;
        border-radius  : 12px;
    }

    .icon-ellipse {
        display  : inline-block;
        position : absolute;
        bottom   : 30px;
        left     : 30px;
        animation: animationFramesOne 20s infinite linear;
    }

    @keyframes animationFramesOne {
        0% {
            transform: translate(0px, 0px) rotate(0deg);
        }

        20% {
            transform: translate(20px, 0px) rotate(36deg);
        }

        40% {
            transform: translate(40px, 60px) rotate(72deg);
        }

        60% {
            transform: translate(60px, 80px) rotate(108deg);
        }

        80% {
            transform: translate(-20px, 45px) rotate(144deg);
        }

        100% {
            transform: translate(0px, 0px) rotate(0deg);
        }
    }

    .banner {
        position      : relative;
        z-index       : 1;
        padding-top   : 100px;
        padding-bottom: 100px;

        .badges {
            &.badge-users {
                top : 35%;
                left: -50px;

                @media only screen and (max-width: 991.98px) {
                    left: -20px;
                }

                @media only screen and (max-width: 575.98px) {
                    left: 0px;
                }

                .badges-inner {
                    span {
                        @include bannerbadges;

                        @media only screen and (max-width: 1024px) {
                            width        : 35px;
                            height       : 35px;
                            border-radius: 8px;

                            img {
                                height: 21px;
                            }
                        }
                    }
                }
            }

            &.badge-colleges {
                bottom: 120px;
                right : -20px;

                .badges-inner {
                    span {
                        @include bannerbadges;

                        @media only screen and (max-width: 1024px) {
                            width        : 35px;
                            height       : 35px;
                            border-radius: 8px;

                            img {
                                height: 21px;
                            }
                        }
                    }
                }

                @media only screen and (max-width: 767.98px) {
                    right: 0;
                }
            }

            &.badge-courses {
                right: 0px;
                top  : 60px;

                @media only screen and (max-width: 1024px) {
                    height: 45px;
                }
            }

            .badges-inner {
                border-radius: 18px;
                background   : #F5F5F4;
                padding      : 20px;
                align-items  : center;
                box-shadow   : 0px 5px 10px rgba($black-color, 0.20);

                @media only screen and (max-width: 991.98px) {
                    border-radius: 12px;
                    padding      : 10px 15px;
                }

                h2 {
                    color      : $heading-color;
                    font-size  : 25px;
                    font-weight: 700;

                    @media only screen and (max-width: 991.98px) {
                        font-size: 18px;
                    }
                }

                p {
                    color      : $heading-color;
                    font-size  : 15px;
                    line-height: 1.5;
                    font-weight: 400;
                    max-width  : 120px;

                    @media only screen and (max-width: 991.98px) {
                        font-size: 12px;
                    }

                }

                &:after {
                    content      : '';
                    background   : $gradient-color;
                    position     : absolute;
                    top          : -1px;
                    bottom       : -1px;
                    left         : -1px;
                    right        : -1px;
                    z-index      : -1;
                    border-radius: 18px;

                    @media only screen and (max-width: 991.98px) {
                        border-radius: 12px;
                    }
                }
            }
        }

        .banner-content {
            .topbar_text_slider {
                .custom-slider {
                    list-style-type: none;
                    padding        : 0;
                    margin         : 0;
                    position       : relative;
                    height         : 100%;
                    display        : flex;
                    flex-direction : column;
                    transition     : transform 0.8s ease-in-out;

                    .slider-item {
                        display    : none;
                        font-size  : 52px;
                        color      : $heading-color;
                        font-weight: 700;
                        line-height: 1.25;

                        @media only screen and (max-width: 1199.98px) {
                            font-size: 42px;
                        }

                        @media only screen and (max-width: 991.98px) {
                            font-size: 30px;
                        }

                        &.active {
                            display  : block;
                            animation: slideIn 0.8s ease-in-out;
                        }
                    }
                }
            }

            @media only screen and (max-width: 767.98px) {
                margin-bottom: 40px;
            }

            @keyframes slideIn {
                from {
                    opacity  : 0;
                    transform: translateY(20px);
                }

                to {
                    opacity  : 1;
                    transform: translateY(0);
                }
            }

            p {
                font-size  : 14px;
                color      : $paragraph-color;
                font-weight: 500;

            }

            h2 {
                font-size    : 26px;
                font-weight  : 700;
                line-height  : 1.25;
                color        : $btn-color;
                margin-bottom: 0;
            }
        }
    }

    .our-goals {
        padding-top   : 60px;
        padding-bottom: 80px;

        @media only screen and (max-width: 575.98px) {
            padding-bottom: 0;
        }

        .goals-inner {
            border       : 1px solid #D8D8D8;
            border-radius: 12px;
            padding      : 20px 20px;
            box-shadow   : 0px 0px 30px rgba($black-color, 0.07);
            height       : 100%;
            background   : $white-color;

            @media only screen and (max-width: 991.98px) {
                height       : auto;
                margin-bottom: 20px
            }

            p {
                color      : $paragraph-color;
                font-size  : 14px;
                line-height: 1.5;
                font-weight: 500;
                margin     : 15px 0px 10px;
                min-height : 85px;

                @media only screen and (max-width: 991.98px) {
                    min-height: auto;
                }
            }

            .goals-icon-text {
                display    : flex;
                align-items: center;

                h2 {
                    font-size    : 20px;
                    color        : $heading-color;
                    font-weight  : 700;
                    margin-bottom: 0px;
                }

                span {
                    width          : 52px;
                    min-width      : 52px;
                    height         : 54px;
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                    border-radius  : 12px;
                    margin-right   : 15px;
                }

                &.goals-color1 {
                    span {
                        background-color: rgba(58, 56, 115, 0.20);
                    }
                }

                &.goals-color2 {
                    span {
                        background-color: #D7DEF5;
                    }
                }

                &.goals-color3 {
                    span {
                        background-color: rgb(185, 109, 244, 0.22)
                    }
                }
            }

            &:hover {
                cursor    : pointer;
                background: $gradient-color;

                .text-gradient {
                    color: $white-color !important;



                    img {
                        filter: brightness(0) invert(1);
                    }
                }

                h2,
                p {
                    color: $white-color
                }

                .goals-icon-text {
                    span {
                        background-color: #F1E8FC;

                        img {
                            display: none;
                        }

                        img+img {
                            display: inline-block !important;
                        }
                    }
                }
            }
        }

    }

    .our-services {
        padding-top   : 60px;
        padding-bottom: 80px;

        @media only screen and (max-width: 991.98px) {
            padding-top   : 40px;
            padding-bottom: 60px;
        }
       
        .services-content {
            .para-large {
                font-size  : 20px;
                color      : #344054;
                line-height: 1.75;

                @media only screen and (max-width: 1199.98px) {
                    font-size: 16px;
                }

                @media only screen and (max-width: 767.98px) {
                    font-size: 14px;
                }
            }

            p {
                color      : #344054;
                font-size  : 15px;
                line-height: 1.65;
                @media only screen and (max-width: 767.98px) {
                        font-size: 14px;
                }
            }
            
        }

        .service-img {
            .service-img-inner {
                &:before {
                    content   : '';
                    position  : absolute;
                    bottom    : 0px;
                    top       : 150px;
                    left      : 0px;
                    right     : 50px;
                    background: rgba(121, 117, 240, 0.20);
                    z-index   : -1;

                    @media only screen and (max-width: 991.98px) {
                        top  : 80px;
                        right: 50px;
                    }

                    @media only screen and (max-width: 767.98px) {
                        top  : 150px;
                        right: 0px;
                    }
                }

                img {
                    margin-bottom: -10px;
                    margin-left  : -40px;

                    @media only screen and (max-width: 767.98px) {
                        margin-left: 0;
                    }
                }
            }

            .peoplesearching {
                border-radius: 12px;
                box-shadow   : 0px 10px 30px rgba($black-color, 0.07);
                max-width    : 237px;
                margin       : 0 auto;
                margin-top   : -60px;
                background   : rgba($white-color, 0.69);
                padding      : 20px;
                text-align   : center;
                transform    : translateX(-25px);
                border       : 1px solid $white-color;

                @media only screen and (max-width: 1199.98px) {
                    max-width: 211px;
                }

                @media only screen and (max-width: 1024px) {
                    padding: 15px;
                }

                @media only screen and (max-width: 767.98px) {
                    transform: initial;
                }

                &:after {
                    content      : '';
                    width        : 61px;
                    height       : 60px;
                    background   : #FAE9D9;
                    position     : absolute;
                    border-radius: 50%;
                    right        : 0px;

                    @media only screen and (max-width: 767.98px) {
                        width : 41px;
                        height: 40px;

                    }
                }

                h5 {
                    font-size    : 16px;
                    color        : #010101;
                    font-weight  : 700;
                    margin-bottom: 5px;
                }

                p {
                    font-size    : 13px;
                    color        : #424242;
                    font-weight  : 400;
                    margin-bottom: 10px;
                }

                .service-users {
                    display        : flex;
                    justify-content: center;
                    align-items    : center;

                    img {
                        width        : 45px;
                        min-width    : 45px;
                        height       : 45px;
                        border-radius: 50%;
                        margin-right : -15px;

                        @media only screen and (max-width: 767.98px) {
                            width    : 35px;
                            min-width: 35px;
                            height   : 35px;
                        }
                    }

                    span {
                        width          : 45px;
                        height         : 45px;
                        min-width      : 45px;
                        background     : #B96DF4;
                        border-radius  : 50%;
                        display        : flex;
                        align-items    : center;
                        justify-content: center;
                        color          : $white-color;
                        font-size      : 14px;
                        font-weight    : 600;

                        @media only screen and (max-width: 767.98px) {
                            width    : 35px;
                            min-width: 35px;
                            height   : 35px;
                        }
                    }
                }
            }
        }
    }
    .service-grid  {
        .service-icon {
            width: 73px;
            height: 70px;
            background: rgba($btn-color, 0.38);
            border-radius: 16px;
            margin-bottom: 30px;
        }
        h3 {
            font-size: 20px;
            color: #101130;
            font-weight: 600;
            margin-bottom: 15px;
            @media only screen and (max-width: 991.98px) {
                    font-size: 16px;
            }
        }
        p {
            font-size: 14px!important;
            font-weight: 500;
            color: $paragraph-color2;
            max-width: 100%;
            margin-bottom: 0px;
        }
    }
    .portal {
        padding-top   : 60px;
        padding-bottom: 80px;

        @media only screen and (max-width: 991.98px) {
            padding-top   : 40px;
            padding-bottom: 60px;
        }

        .portal-inner {
            border       : 5px solid $footer-bg;
            border-radius: 20px;
            max-width    : 1100px;
            margin       : 0px auto;
            height       : 727px;
            overflow     : hidden;
            background   : $light-color;

            @media only screen and (max-width: 1024px) {
                height: 600px;
            }

            @media only screen and (max-width: 991.98px) {
                height: 550px;
            }

            @media only screen and (max-width: 767.98px) {
                height: 350px;
            }

            @media only screen and (max-width: 575.98px) {
                height: 250px;
            }

            img {
                &.top-img {
                    position: absolute;
                    top     : 0px;
                }
            }

            .bottom-img {
                animation : moveUpDown 12s infinite;
                margin-top: 41px;
            }
        }
    }

    @keyframes moveUpDown {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-200px);
        }
    }

    .partners {
        padding-bottom: 70px;
        padding-top   : 70px;

        .partners-img {
            @media only screen and (max-width: 575.98px) {
                justify-content: space-around !important;
            }

            img {
                height: 38px;

                @media only screen and (max-width: 767.98px) {
                    padding   : 3px;
                    width     : auto;
                    object-fit: contain;
                }

                @media only screen and (max-width: 575.98px) {
                    padding: 0px;
                    margin : 5px 5px;
                    height : 32px !important;
                }
            }
        }

    }

    .stats-count {
        padding-top   : 60px;
        padding-bottom: 80px;
    }

    .united-inner {
        .united-list {
            input[type="checkbox"] {
                display: none;

                &:checked+label {
                    border: 1px solid $btn-color;
                    color : $btn-color;
                }
            }

            label {
                border         : 1px solid #D8D8D8;
                border-radius  : 12px;
                padding        : 20px 25px;
                box-shadow     : 0px 0px 30px rgba($black-color, 0.07);
                width          : 100%;
                height         : 100%;
                display        : flex;
                align-items    : center;
                font-weight    : 500;
                line-height    : 1.5;
                min-height     : 90px;
                cursor         : pointer;
                color          : #0D1111;
                justify-content: center;
                text-align     : center;
                font-size      : 15px;
            }
        }
    }

    .newsletter {
        padding-top   : 100px;
        padding-bottom: 100px;
        background    : $gradient-color;

        .newsletter-inner {
            border       : 10px solid $white-color;
            border-radius: 8px;
            padding      : 50px 30px;
            text-align   : center;

            @media only screen and (max-width: 767.98px) {
                padding: 30px 30px;
                border : 6px solid $white-color;
            }

            h2 {
                color    : $white-color;
                font-size: 40px;

                @media only screen and (max-width: 1024px) {
                    font-size: 30px;
                }
            }

            p {
                font-size  : 14px;
                color      : $white-color;
                line-height: 1.65;
                max-width  : 706px;
                margin     : 0 auto 15px;
            }

            .input-group {
                border       : 1px solid $white-color;
                border-radius: 4px;
                height       : 58px;
                max-width    : 840px;
                margin       : 0 auto;

                span {
                    &.input-group-icon {
                        margin-left: 22px;
                        display    : flex;
                        align-items: center;

                        @media only screen and (max-width: 991.98px) {
                            img {
                                height: 18px;
                            }
                        }

                        @media only screen and (max-width: 767.98px) {
                            position   : absolute;
                            top        : 16px;
                            left       : 13px;
                            margin-left: 0px;
                        }

                    }
                }

                @media only screen and (max-width: 767.98px) {
                    border         : none;
                    height         : auto;
                    border-radius  : 0px;
                    max-width      : 100%;
                    justify-content: center;
                    width          : 100%;
                }

                .form-control {
                    font-size  : 16px;
                    font-weight: 500;
                    color      : $white-color;
                    background : transparent;
                    border     : none;
                    outline    : none;
                    box-shadow : none;
                    height     : auto;
                    line-height: 1.5;

                    @media only screen and (max-width: 991.98px) {
                        font-size: 14px;
                    }

                    @media only screen and (max-width: 767.98px) {
                        padding-left : 40px;
                        border       : 1px solid #fff;
                        border-radius: 4px !important;
                        height       : 50px;
                        width        : 100%;
                    }

                    &::placeholder {
                        color      : $white-color;
                        font-weight: 400;
                    }
                }

                button {
                    font-size  : 16px;
                    background : $white-color;
                    border     : none;
                    min-width  : 267px;
                    font-weight: 600;
                    color      : $btn-color;
                    margin-right: -2px;
                    @media only screen and (max-width: 991.98px) {
                        font-size: 14px;
                        min-width: 215px;
                    }

                    @media only screen and (max-width: 767.98px) {
                        height       : 50px;
                        border-radius: 4px !important;
                        margin-top   : 12px;
                    }

                    &:hover {
                        opacity: 0.75;
                    }
                }
            }
        }
    }

    .empowering-aboutus {
        padding-bottom: 120px;
        @media only screen and (max-width: 991.98px) {
            padding-bottom: 60px;
        }
        .aboutus-content {
            .heading {
                h2 {
                    span {
                        color:$primarynew-color;
                    }
                }
            }
        }
    }

    .aboutus-content {
        p {
            font-size    : 14px;
            font-weight  : 500;
            color        : #475569;
            margin-bottom: 20px;
        }
    }

    .strengthening-aboutus {
        padding-bottom: 100px;
        @media only screen and (max-width: 991.98px) {
            padding-bottom: 60px;
        }
        .aboutus-content {
            .heading {
                h2 {
                    span {
                        color:$primarynew-color;
                    }
                }
            }
        }

        .strengthening-aboutus-img {
            video {
                height       : 410px;
                width        : 100%;
                object-fit   : cover;
                border-radius: 18px;
            }

            &:after {
                background   : #F6F5FA;
                content      : '';
                position     : absolute;
                top          : 0px;
                left         : 60px;
                right        : 0px;
                bottom       : -20px;
                z-index      : -1;
                border-radius: 18px;
            }
        }
    }
    .howitworks {
        padding-top: 80px;
        padding-bottom: 80px;
        .howitworkshead {
            font-size: 62px;
            color: $heading-color;
            font-weight: 700;
            text-align: center;
        }
        .howitworksinner {
            position: relative;
            background-color: rgba(121, 117, 240, 0.05);
            padding: 30px;
            border-radius: 10px;
            .countno {
                position: absolute;
                right: 0;
                top: 0;
                font-size: 50px;
                color: rgba($black-color,0.10);
                font-weight: 700;
            }
        }
    }
}

.subscribeModal{
    .modal-content{
        border-radius: 20px;
        .modal-body {
            padding: 50px 30px;
            text-align: center;
            button{
                &.btnclose {
                    position: absolute;
                    top: -15px;
                    padding: 0;
                    right: -20px;
                    width: 30px;
                    height: 30px;
                    background-color: $white-color;
                    opacity: 1;
                    border-radius: 50%;
                    background-size: 10px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
                }
            }
            
            h2 {
                color: $heading-color;
                font-size: 32px;
                font-weight: 700;
            }
            p {
                margin-bottom: 0px;
            }
            
            img {
                height: 102px;
                margin-bottom: 23px;
            }
        }
    }
}
@media only screen and (max-width: 1199.98px) {
    @keyframes moveUpDown {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-100px);
        }
    }
}

@media only screen and (max-width: 767.98px) {
    @keyframes moveUpDown {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-50px);
        }
    }
}

@media only screen and (min-width:1200px) and (max-width:1440px) {
    .container-big {
        max-width: 1280px;
    }
}

@media only screen and (min-width:1300px) and (max-width:1440px) {
    .container-big {
        max-width: 1280px;
    }
}
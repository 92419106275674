@import "./Variables.scss";

.btn-primary , .btn-primary:disabled{
  font-size: 1em;
  border-radius: 6px;
  padding: 0.7em 1.3em;

  font-weight: 500;
  background-color: $btn-color;
  border-color: $btn-color;
  color: $white-color;
  transition: 0.25s ease-in;
  &:hover {
    background-color: $white-color !important;
    border-color: $btn-color !important;
    color: $secondary-color !important;
  }
  &:focus {
    background-color: $btn-color;
    border-color: $btn-color;
    color: $secondary-color;
  }
}

.btn-outline-primary {
  font-size: 1em;

  border-radius: 6px;
  padding: 0.7em 1.3em;
  font-weight: 500;
  background-color: $white-color;
  border-color: $btn-color;
  color: $secondary-color;
  transition: 0.25s ease-in;
  &:hover {
    background-color: $btn-color !important;
    border-color: $btn-color !important;
    color: $white-color !important;
  }
  &:focus {
    // background-color: $btn-color;
    // border-color: $btn-color;
    color: $secondary-color!important;
  }
}
.btn-primaryLight , .btn-primaryLight:disabled {
  font-size: 1em;
  border-radius: 6px !important;
  padding: 0.7em 1.3em !important;

  font-weight: 500;
  background-color: rgba($btn-color, 0.12) !important;
  border: 0px !important;
  color: $btn-color !important;
  transition: 0.25s ease-in;
  opacity: 1;
  &:hover {
    background-color: $btn-color !important;
    color: $white-color !important;
  }
  &:focus {
    background-color: $btn-color !important;
    color: $white-color !important;
  }
}

$primary-color: #3A3873;
$btn-color: #7B76F1;
$secondary-color: #231F2F;
$light-color: #F1F1F1;
$white-color: #ffffff;
$black-color: #000000;
$transparent-color: transparent;
$grey-color: #666666;
$footer-bg:#0F172A;
$primary-color1:#B96DF4;
$red-color:#ff0000;
$heading-color: #101828;
$paragraph-color: #646464;
$paragraph-color2: #606176;
$gradient-color: linear-gradient(to bottom, #7B76F1, #B96DF4);
$primarynew-color:#607DDB


/* Chat Css */
.chat-window {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 1em;
  @media only screen and (max-width:767.98px){
    flex-direction: column;
  }
}

.chat-cont-left {
  padding: 0.6em 0;
  flex: 0 0 33%;
  left: 0;
  margin-right: 0;
  max-width: 33%;
  position: relative;
  z-index: 4;
  background: $white-color;
  border-radius: 0;
  @media only screen and (max-width:767.98px){
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.chat-window .chat-scroll {
  height: 400px;
  overflow-y: auto;
  scrollbar-color: #e3e3e3  #f9f9f9;
  scrollbar-width: thin;
}
.chat-window .chat-scroll1 {
  //height: 370px;
  height: 55vh;
  overflow-y: auto;
  scrollbar-color: #e3e3e3  #f9f9f9;
  scrollbar-width: thin;
  padding-right: 8px;
}

.chat-window .chat-scroll1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.chat-window .chat-scroll1::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.chat-window .chat-scroll1::-webkit-scrollbar-thumb {
  background-color: $primary-color;
}

.chat-window .chat-scroll {
  a {
    text-decoration: none;
    color: $black-color;
  }
  .user-last-chat {
    font-size: 0.75em;
    color: #727272;
    text-decoration: none;
  }
  .last-chat-time {
    font-size: 0.8em;
    text-decoration: none;
    color: #727272;
  }
  .media {
    &.active {
      background-color: $light-color;
    }
  }
}

.chat-cont-left .chat-users-list a.media {
  background: $white-color;
  padding: 0.8em 1.3em;
  transition: all 0.2s ease 0s;
}

.chatheading {
  font-size: 0.875em;
  margin-bottom: 1em;
  padding: 0 1.3em;
}

.media-img-wrap {
  margin-right: 15px;
  position: relative;
}

.media-img-wrap .avatar {
  position: relative;
  height: 50px;
  width: 50px;
}

.media-img-wrap .avatar .dotsOnline {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 100px;
  border: 2px solid #fff;
  position: absolute;
  bottom: 0;
  right: 4px;
}

.media-img-wrap .avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // border: 3px solid $btn-color;
}

.media-img-wrap {
  margin-right: 8px;
  position: relative;
}

.chat-cont-left
  .chat-users-list
  a.media
  .media-body
  > div:first-child
  .user-name {
  color: $black-color;
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  max-width: 180px;
}

.chat-cont-left {
  .chat-users-list {
    .document_Header {
      background-color: transparent;
      margin-bottom: 1.3em;
      padding: 0.7em 1em 0em;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
}

.inPutBox {
  flex-basis: auto;
  flex-grow: 1;
  position: relative;
  .form-control {
    border-radius: 100px;
    background-color: #f2f3f5;
    border-color: #f2f3f5;
    padding-left: 3.5em;
  }
  img {
    position: absolute;
    top: 12px;
    left: 1em;
  }
  &.inPutBox1 {
    .form-control {
      border-radius: 5px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);

      background-color: $white-color;
    }
  }
}

.chat-cont-right {
  flex: 0 0 67%;
  max-width: 67%;
  background: $white-color;
  padding: 0;
  overflow: hidden;
  @media only screen and (max-width:767.98px){
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.chat-cont-right .chat-body {
  background-color: $white-color;
  padding: 0;
}

.chat-cont-right .chat-body ul.list-unstyled {
  margin: 0 auto;
  padding: 0 1em;
  width: 100%;
}

.chat-cont-right {
  .chat-body {
    .userInfo {
      background-color: #f1f4f6;
      padding: 0.6em 1em;
      border-radius: 0;
      margin-bottom: 1.2em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .u_media {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          width: 52px;
          height: 52px;
          object-fit: cover;
        }
        h2 {
          font-size: 1em;
          margin-bottom: 0.2em;
          color: $black-color;
        }
        p {
          margin: 0;
          color: #5bca49;
          font-size: 0.775em;
        }
      }
      svg {
        cursor: pointer;
      }
      .dropdown {
        .dropdown-toggle {
          background-color: transparent;
          border-color: transparent;
          color: #878787;
          &::after {
            display: none;
          }
        }
        .dropdown-menu {
          border-color: transparent;
          border-radius: 16px;
        }
      }
    }
  }
}

.chat-cont-right .chat-body .media.received {
  margin-bottom: 20px;
  position: relative;
}

.chat-cont-right .chat-body .media.received {
  gap: 1em;
}

.chat-cont-right .chat-body .media .avatar {
  height: 30px;
  width: 30px;
  bottom: 0;
  position: absolute;
}

.chat-cont-right .chat-body .media.received .media-body .msg-box {
  position: relative;
}

.chat-cont-right .chat-body .media.received .media-body .msg-box > div {
  border-radius: 0px 7px 7px 7px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
}

.chat-cont-right .chat-body .media .media-body .msg-box > div {
  padding: 5px 10px;
  border-radius: 7px 7px 7px 0;
  display: inline-block;
  position: relative;
}
.chatdate{
  background: rgba($btn-color, 0.1);
  color:$btn-color;
  padding: 2px 9px;
  border-radius: 4px;
  font-size: 12px;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div p {
  color: #0f1828;
  margin-bottom: 0;
  font-size: 13px;
}

.chat-cont-right .chat-body .media.received .chat-msg-info {
  text-align: right;
}

.chat-cont-right .chat-body .media .media-body .msg-box > div img {
  margin-bottom: 0.6em;
}

.chat-cont-right .chat-body .media.received li {
  padding-right: 0;
}

.chat-cont-right .chat-body .media.received .chat-msg-info {
  justify-content: flex-end;
  align-items: flex-end;
}

.chat-msg-info {
  align-items: center;
  display: flex;
  clear: both;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}

.chat-msg-info li {
  font-size: 13px;
  padding-right: 16px;
  position: relative;
}

.chat-cont-right
  .chat-body
  .media.received
  .media-body
  .msg-box
  > div
  .chat-time {
  color: #000000;
  opacity: 0.5;
  display: flex;
  align-items: center;
  gap: 0.2em;
  text-align: end;
  justify-content: flex-end;
  font-size: 11px;
  margin-left: 4px;
  transform: translateY(2px);
  min-width: 55px
}

.chat-cont-right .chat-body .media.sent {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  gap: 1em;
}

.chat-cont-right .chat-body .media.sent .avatar {
  height: 30px;
  width: 30px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.chat-cont-right .chat-body .media.sent .media-body {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 0;
}

.chat-cont-right .chat-body .media.sent .media-body .msg-box {
  padding-left: 50px;
  position: relative;
}

.chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
  border-radius: 20px 0px 20px 20px;
  background: rgba(99, 100, 255, 0.15);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
  color: $black-color;
}

.chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
  color: $black-color;
  margin: 0;
}

.chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div
  .chat-msg-info {
  flex-direction: row-reverse;
}

.chat-cont-right
  .chat-body
  .media.sent
  .media-body
  .msg-box
  > div
  .chat-msg-info
  li {
  padding-left: 16px;
  padding-right: 0;
  position: relative;
}

.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
  color: $black-color;
  opacity: 0.5;
  min-width: 55px;
  text-align: end;
  font-size: 11px;
  margin-left: 4px;
  transform: translateY(2px);
}

.chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
  border-radius: 7px 7px 0px 7px;
}

.chat-cont-right .chat-body .avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #e6e6e6;
}
.user-last-chat {
  font-size: 14px;
}
.chat-cont-right .chat-footer {
  background-color: $white-color;
  border-top: 1px solid #f0f0f0;
  padding: 10px 15px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5em;
    svg{
      width: 24px;
      cursor: pointer;
    }
}
.chat-cont-left .chat-users-list a.media .media-body {
  display: flex;
  justify-content: space-between;
}

.chat-cont-right .chat-footer .input-group {
  width: 100%;
  background-color: #f5f5f6;
  border: none;
  border-radius: 10px;
  padding: 0px 6px 0px 0px;
  align-items: center;
  .form-control {
    margin-bottom: 0;
  }
}

.chat-cont-right .chat-footer .input-group .form-control {
  background-color: #f5f5f6;
  border: none;
  border-radius: 50px;
  margin-right: 1em;
}

.chat-cont-right .chat-footer .input-group .btn.msg-send-btn {
  background-color: $btn-color;
  border-color: $btn-color;
  border-radius: 38px;
  width: 38px;
  height: 38px;
  color: $white-color;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.listScroll {
  overflow-x: auto;
}
.chat-cont-right {
  .userHeaderflex {
    display: flex;
    .media-body {
      text-align: left;
      align-items: center;
      display: flex;
      .user-name {
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        text-transform: capitalize;
        //max-width: 180px;
      }
      .user-last-chat {
        font-size: 0.875em;
        color: #727272;
        text-decoration: none;
      }
    }
  }
}

.iconBoxHeader {
  display: flex;
  gap: 1.6em;
  svg {
    font-size: 1.6em;
    color: $black-color;
    transition: all ease 0.3s;
    &:hover {
      color: $btn-color;
    }
  }
}

.imgfixchat {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
}

.centerChatBox {
  display: flex;
  .chatsmallbox {
    flex-grow: 2;
    // width: 1550px;
  }
  .chatInformation {
    flex-grow: 1;
    flex-basis: 30%;
    height: 690px;
    overflow-y: auto;
    scrollbar-color: #090119 #f9f9f9;
    scrollbar-width: thin;
  }
}

.chatInformation {
  padding: 0 1em;
  .delContact {
    display: flex;
    align-items: center;
    gap: 0.4em;
    margin-bottom: 1.3em;
    span {
      color: $black-color;
    }
    svg {
      font-size: 1.3em;
    }
  }
  .infochatImageBox {
    text-align: center;
    img {
      margin-bottom: 0.7em;
      width: 103px;
      height:103px;
      object-fit: cover;
      border-radius: 50%;
    }
    a {
      margin: 0 0.4em;
    }
    h3 {
      text-transform: capitalize;
      font-size: 1.375em;
      color:$black-color;
    }
    p {
      color: $black-color;
      opacity: 0.6;
      font-size: 12px;
    }
  }
  .memberBox {
    h5 {
      font-size: 0.875em;
      font-weight: 500;
      margin-bottom: 1em;
    }
    .memberBoxflex {
      display: flex;
      justify-content: space-between;
      gap: 0.3em;
      flex-wrap: wrap;
      &.memberMedia {
        img {
          width: 60px;
          height: 60px;
          border: 1px solid $btn-color;
          border-radius: 100px;
          padding: 0.2em;
        }
        a {
          img {
            padding: 0;
            border: 0;
          }
        }
      }
      &.memberMedia1 {
        img {
          width: 60px;
          height: 60px;
          border-radius: 5px;
        }
      }
    }
  }
  .muteBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    h3 {
      font-size: 0.875em;
      margin: 0;
    }
  }
  .chatLogout {
    display: flex;
    align-items: center;
    gap: 0.3em;
    margin-bottom: 1em;
    font-size: 1.6em;
    color: #a70000;
    svg{
      width: 20px;
    }
    h3 {
      font-size:14px;
      margin: 0;
    }
  }
  .form-check-input:checked {
    border-radius: 100px !important;
  }
}

.userModalList {
  margin-top: 1.3em;
  margin-bottom: 1.3em;
  li {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 58px;
      height: 58px;
      padding: 0.2em;
      border: 1px solid $btn-color;
      border-radius: 100px;
    }
    h3 {
      font-size: 1em;
      font-weight: 600;
      margin-bottom: 0.3em;
    }
    p {
      margin: 0;
      font-size: 0.875em;
    }
    .checkRight {
      margin-left: auto;
    }
  }
}

.modal_foot {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.6em;
  .btn {
    width: 150px;
  }
}
.memberadd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color:$black-color;
  img{
    margin-bottom: 3px;
  }
}.memberchat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color:$black-color;
  text-align: center;
  img{
    margin-bottom: 3px;
  }
}
.hover-content {
  display: none; /* Hide the div initially */
}

.showdelete:hover{
  cursor: pointer;
  .hover-content{
    display: block;
  }
}